import { FooterContent } from '@/api/interfaces/content/app/footerContent';

export const footerContent: FooterContent = {
    footerLinks: [
        {
            text: 'Impressum',
            url: 'https://www.ewe-netz.de/meta/impressum',
            title: 'Impressum',
        },
        {
            text: 'Datenschutz',
            url: 'https://www.ewe-netz.de/meta/datenschutz',
            title: 'Datenschutz',
        },
        {
            text: 'Kontakt',
            url: 'https://www.ewe-netz.de/meta/kontakt',
            title: 'Kontakt',
        },
    ],
};
