import { CheckAndSendFormStepContent } from '@/api/interfaces/content/form/steps/checkAndSendFormStepContent';

export const checkAndSendContent: CheckAndSendFormStepContent = {
    stepHeading: 'Überprüfen & Absenden',
    buttonNext: {
        text: 'Weiter',
    },
    checkNotice: {
        text:
            'Bitte überprüfen Sie Ihre Angaben. Durch Scrollen werden diese sichtbar und können durch Anklicken des Stiftes geändert werden.',
    },
    comments: {
        label: 'Bemerkung',
    },
    summaryDocument: {
        label: 'Zusammenfassung Ihrer Daten',
    },
    privacyInformation: {
        label:
            'Ich habe die <a href="http://local.deaapi.ewe-netz.de/-/media/dea/dokumente/formular/2019_02_01_datenschutzinfo_art_13_14_dsgvo.pdf?la=de-de&amp;hash=706FE6A30F9B391AA1A416E44375E63B2545A8FB">Datenschutzinformationen</a> der EWE NETZ GmbH gem&auml;&szlig; Art. 13 und 14 DS-GVO zur Kenntnis genommen.',
        summaryLabel:
            'Ich habe die <a href="http://local.deaapi.ewe-netz.de/-/media/dea/dokumente/formular/2019_02_01_datenschutzinfo_art_13_14_dsgvo.pdf?la=de-de&amp;hash=706FE6A30F9B391AA1A416E44375E63B2545A8FB">Datenschutzinformationen</a> zur Kenntnis genommen.',
    },
    generalTermsConsentNS: {
        label:
            'Ja, ich stimme den <a href="https://www.ewe-netz.de/~/media/ewe-netz/downloads/2019_01_23_avbs_ewe_netz.pdf">allgemeinen Vertragsbedingungen</a>, den <a href="https://www.ewe-netz.de/~/media/ewe-netz/downloads/20200701_ergaenzende_bedingungen_strom.pdf">Ergänzenden Bedingungen Strom</a> und den <a href="http://local.deaapi.ewe-netz.de/-/media/dea/dokumente/formular/technische_anschlussbedingungen_tab_ns_m_beiblatt_n.pdf?la=de-de&amp;hash=56343071B5200D326E8AFCDC9183288705CF6D64">technischen Anschlussbedingungen Strom</a> zu.',
        summaryLabel:
            'Ich stimme den <a href="https://www.ewe-netz.de/~/media/ewe-netz/downloads/2019_01_23_avbs_ewe_netz.pdf">allgemeinen Vertragsbedingungen</a> zu.',
    },
    generalTermsConsentMS: {
        label:
            'Ja, ich stimme den <a href="https://www.ewe-netz.de/~/media/ewe-netz/downloads/2019_01_23_avbs_ewe_netz.pdf">allgemeinen Vertragsbedingungen</a>, den <a href="https://www.ewe-netz.de/~/media/ewe-netz/downloads/20200701_ergaenzende_bedingungen_strom.pdf">Ergänzenden Bedingungen Strom</a> und den <a href="http://local.deaapi.ewe-netz.de/-/media/dea/dokumente/formular/2019_07_01_techn_anschlussbedingungen_ewe_netz-erg-vorg-vde.pdf?la=de-de&amp;hash=10BA4DD19FFEE9FC40080A377B2640D596765AB0">technischen Anschlussbedingungen Strom</a> zu.',
        summaryLabel:
            'Ich stimme den <a href="https://www.ewe-netz.de/~/media/ewe-netz/downloads/2019_01_23_avbs_ewe_netz.pdf">allgemeinen Vertragsbedingungen</a> zu.',
    },
    correctnessNotice: {
        text:
            '<strong style="color: red">Hinweis</strong>: Sollten die Daten der eingereichten Unterlagen von dem tatsächlichen Aufbau abweichen, kann es zum Abbruch der Inbetriebnahme Ihrer Eigenerzeugungsanlage führen. Eine erneute Anfahrt wird kostenpflichtig. Bitte teilen Sie uns Abweichungen rechtzeitig vor Inbetriebnahme als Änderungsmeldung über dieses Portal mit.',
    },
};
