import { InverterFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/inverterContent';

export const inverterContent: InverterFormGroupContent = {
    inverterHeadline: {
        text: 'Wechselrichter',
    },
    inverterManufacturerAndModuleType: {
        label: 'Hersteller und Modultyp',
        manufacturer: {
            placeholder: 'Muster',
        },
        moduleType: {
            placeholder: 'Muster Boy 2.5',
        },
    },
    inverterQuantity: {
        label: 'Anzahl',
    },
    inverterContributionSymmetricalShortCircuitCurrent: {
        label: 'Beitrag zum Anfangskurzschlusswechselstrom I<sub>k</sub>',
        placeholder: '50',
        unit: 'kA',
        info:
            'F&uuml;r eine Absch&auml;tzung kann der Anteil aus den Erzeugungseinheiten ohne Wechselrichter (I<sub>k</sub>) und der Effektivwert des Quellstroms aus den Erzeugungseinheiten (I<sub>skPF</sub>) addiert werden.',
    },
    inverterVoltageSymmetricalShortCircuitCurrent: {
        label:
            'Bei welcher Spannung ist der Beitrag zum Anfangskurzschlusswechselstrom',
        placeholder: '400',
        unit: 'V',
    },
    inverterContributionSustainedShortCircuitCurrent: {
        label: 'Beitrag zum Dauerkurzschlussstrom I<sub>k</sub>',
        placeholder: '20',
        unit: 'kA',
    },
    inverterVoltageSustainedShortCircuitCurrent: {
        label:
            'Bei welcher Spannung ist der Beitrag zum Dauerkurzschlusswechselstrom',
        placeholder: '400',
        unit: 'V',
    },
    inverterActivePowerAndApparentPower: {
        label: 'Wirk- und Scheinleistung',
        activePower: {
            placeholder: '120',
            unit: 'kW',
        },
        apparentPower: {
            placeholder: '125',
            unit: 'kVA',
        },
    },
    inverterTotalActivePower: {
        label: 'Summe Wirkleistung',
        unit: '{{ value }} kW',
    },
    inverterTotalApparentPower: {
        label: 'Summe Scheinleistung',
        unit: '{{ value }} kVA',
    },
    inverterAddRemoveModule: {
        addLabel: 'Wechselrichter hinzuf&uuml;gen',
        removeLabel: 'Wechselrichter entfernen',
    },
    inverterOverallActivePower: {
        label: 'Gesamtergebnis aus Summe Wirkleistung',
        unit: '{{ value }} kW',
    },
    inverterOverallActivePowerWarning: {
        text:
            'Die Wechselrichterleistung weicht wesentlich von der Modulleistung ab. Haben Sie die richtigen Leistungen gepflegt?',
    },
    inverterOverallApparentPower: {
        label: 'Gesamtergebnis aus Summe Scheinleistung',
        unit: '{{ value }} kVA',
    },
};
