













import Vue, { PropType } from 'vue';
import BackgroundOverlay from '@/components/atoms/backgroundOverlay/BackgroundOverlay.vue';
import SideNavigation from '@/components/molecules/sideNavigation/SideNavigation.vue';
import type { SideBarContent } from '@/api/interfaces/content/app/sideBarContent';
import type { MetaNavigationContent } from '@/api/interfaces/content/app/metaNavigationContent';

export default Vue.extend({
    name: 'Sidebar',
    components: {
        BackgroundOverlay,
        SideNavigation,
    },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        sideBarContent: {
            type: Object as PropType<SideBarContent>,
            default: function () {
                return {};
            },
        },
        sideNavigationItems: {
            type: Array,
            default: () => [],
        },
        metaNavigationContent: {
            type: Object as PropType<MetaNavigationContent>,
            default: function () {
                return {};
            },
        },
    },
});
