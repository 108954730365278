import { PlantOperatorFormStepContent } from '@/api/interfaces/content/form/steps/plantOperatorFormStepContent';

export const plantOperatorContent: PlantOperatorFormStepContent = {
    stepHeading: 'Anlagenbetreiber',
    buttonNext: {
        text: 'Weiter',
    },
    separatePropertyOwner: {
        label: 'Gibt es einen separaten Grundstückseigentümer?',
        options: {
            no: {
                label:
                    'Nein, der Anlagenbetreiber entspricht dem Grundstückseigentümer',
            },
            yes: {
                label:
                    'Ja, der Anlagenbetreiber entspricht nicht dem Grundstückseigentümer',
            },
        },
    },
    plantBuilder: {
        label: 'Wer errichtet die Anlage?',
        options: {
            operator: {
                label: 'Die Anlage wird vom Betreiber installiert',
            },
            contractor: {
                label:
                    'Die Installation erfolgt durch einen Elektrofachbetrieb',
            },
        },
    },
    salutation: {
        label: 'Anrede',
        summaryLabel: 'Anrede angegeben als',
        options: {
            mr: {
                label: 'Herr',
            },
            mrs: {
                label: 'Frau',
            },
            company: {
                label: 'Firma',
            },
        },
    },
    companyName: {
        label: 'Firmenname',
        placeholder: 'Firmenname',
    },
    title: {
        label: 'Titel',
        summaryLabel: 'Titel angegeben als',
        options: {
            untitled: {
                label: 'Kein Titel',
            },
            dr: {
                label: 'Dr.',
            },
            prof: {
                label: 'Prof.',
            },
            profDr: {
                label: 'Prof. Dr.',
            },
        },
    },
    operatorName: {
        label: 'Vor- und Nachname',
        firstName: {
            placeholder: 'Vorname',
        },
        lastName: {
            placeholder: 'Nachname',
            info:
                'Bitte geben Sie hier nur eine Person an, f&uuml;r weitere Betreiber wie z. B. einen Ehepartner w&auml;hlen Sie bitte "weiteren Anlagenbetreiber hinzuf&uuml;gen".',
        },
    },
    contactPersonName: {
        label: 'Vor- und Nachname des Ansprechpartners',
        firstName: {
            placeholder: 'Vorname',
        },
        lastName: {
            placeholder: 'Nachname',
            info:
                'Bitte geben Sie hier nur eine Person an, f&uuml;r weitere Betreiber wie z. B. einen Ehepartner w&auml;hlen Sie bitte "weiteren Anlagenbetreiber hinzuf&uuml;gen".',
        },
    },
    birthday: {
        label: 'Geburtsdatum',
        info: 'Bitte geben Sie das Geburtsdatum im Format TT.MM.JJJJ ein.',
        placeholder: 'Geburtstag',
    },
    addRemovePlantOperator: {
        addLabel: 'Weiteren Anlagenbetreiber hinzuf&uuml;gen (z.B. Ehepartner)',
        removeLabel: 'Anlagenbetreiber entfernen',
    },
    country: {
        label: 'Land',
        summaryLabel: 'Land angegeben als',
        options: {
            deu: {
                label: 'Deutschland',
            },
            aut: {
                label: 'Österreich',
            },
            dnk: {
                label: 'Dänemark',
            },
            fra: {
                label: 'Frankreich',
            },
            nld: {
                label: 'Niederlande',
            },
            pol: {
                label: 'Polen',
            },
            che: {
                label: 'Schweiz',
            },
        },
    },
    usePlantLocation: {
        label: 'Adressdaten vom Anlagenstandort &uuml;bernehmen',
    },
    zipAndCity: {
        label: 'PLZ und Ort',
        zip: {
            placeholder: 'PLZ',
        },
        city: {
            placeholder: 'Ort',
        },
    },
    streetAndHousenumber: {
        label: 'Stra&szlig;e und Hausnummer',
        street: {
            placeholder: 'Straße',
        },
        housenumber: {
            placeholder: 'Nr.',
        },
    },
    email: {
        label: 'E-Mail',
        placeholder: 'E-Mail',
    },
    phoneNumber1: {
        label: 'Telefon- oder Mobilfunknummer I',
        placeholder: 'Telefon- oder Mobilfunknummer I',
    },
    phoneNumber2: {
        label: 'Telefon- oder Mobilfunknummer II',
        placeholder: 'Telefon- oder Mobilfunknummer II',
    },
};
