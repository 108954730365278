import { FormElement, FormElementConfig } from '../formElement';
import { FormGroup } from '../formGroup';
import { cloneDeep as _cloneDeep } from 'lodash';
import { ReferenceFormGroup } from '../formGroups/referenceFormGroup';

type FormListConfig = {
    initializeItems?: number;
    maxItemCount?: number;
    minItemCount?: number;
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
export class FormList<
    T extends FormElement | ReferenceFormGroup,
    ItemConfig extends FormElementConfig
> extends FormGroup {
    public items: T[] = [];

    private maxItemCount?: number;
    private minItemCount?: number;

    public constructor(
        public readonly itemType: new (
            config: ItemConfig,
            id?: string | undefined,
        ) => T,
        public readonly config: ItemConfig & FormListConfig,
    ) {
        super(config);

        const initializeItems = this.config.initializeItems ?? 0;

        for (let index = 0; index < initializeItems; index++) {
            this.createItem();
        }

        this.maxItemCount = this.config.maxItemCount;
        this.minItemCount = this.config.minItemCount;
    }

    public canAddItem(): boolean {
        if (this.maxItemCount == null) {
            return true;
        }

        return this.items.length < this.maxItemCount;
    }

    public canRemoveItem(): boolean {
        if (this.minItemCount == null) {
            return true;
        }

        return this.items.length > this.minItemCount;
    }

    public createItem(id: string | undefined = undefined): void {
        if (this.canAddItem()) {
            this.items.push(
                new this.itemType(_cloneDeep(this.config as ItemConfig), id),
            );
        }
    }

    public removeItem(item: T): void {
        if (this.canRemoveItem()) {
            this.items = this.items.filter((x) => x !== item);
        }
    }

    public removeAt(index: number): void {
        if (this.canRemoveItem()) {
            this.items = this.items.filter((item, i) => i !== index);
        }
    }

    /** Don't use in user-context, i.e. in the frontend, where the user removes items via click!
     * This is for cases like a technical sync operation, where you need to delete items
     * without concerning yourself with min or max allowed item counts.
     */
    public sudoRemoveItem(item: T): void {
        this.items = this.items.filter((x) => x !== item);
    }

    /** Don't use in user-context, i.e. in the frontend, where the user removes items via click!
     * This is for cases like a technical sync operation, where you need to delete items
     * without concerning yourself with min or max allowed item counts.
     */
    public sudoRemoveAt(index: number): void {
        this.items = this.items.filter((item, i) => i !== index);
    }

    public pop(): void {
        if (this.canRemoveItem()) {
            this.items.pop();
        }
    }
}
