import { formDtoAttribute } from './formDtoAttribute';
import { FormStep } from './formStep';

export abstract class Form {
    @formDtoAttribute({
        jsonSchema: {
            type: 'string',
        },
    })
    public currentStep: string;

    @formDtoAttribute({
        jsonSchema: {
            type: 'string',
        },
    })
    public maxReachedStep: string;

    public editStep: string | null;
    public steps: string[];

    @formDtoAttribute({
        jsonSchema: {
            type: 'number',
        },
    })
    public version: number;

    public serverSideMigration: boolean;

    public constructor(config: Form) {
        this.steps = config.steps ?? [];
        this.currentStep =
            config.currentStep ?? this.steps.length ? this.steps[0] : '';
        this.maxReachedStep = this.currentStep;
        this.editStep = config.editStep;
        this.version = config.version || 0;
        this.serverSideMigration = config.serverSideMigration || false;
    }

    public get enabledSteps(): string[] {
        return this.steps.filter((step) => {
            const stepModel = this[step as keyof Form];
            return (
                stepModel &&
                stepModel instanceof FormStep &&
                !stepModel.disabled
            );
        });
    }
}
