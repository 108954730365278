import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { concat } from '@/filters/concat';
import { interpolate } from '@/filters/interpolate';
import { isIE } from '@/utilities/browserDetection';
import { readableFileSize } from '@/filters/readableFileSize';
import vClickOutside from 'v-click-outside';

Vue.config.productionTip = false;

Vue.use(Vuelidate);

Vue.filter('concat', concat);
Vue.filter('interpolate', interpolate);
Vue.filter('readableFileSize', readableFileSize);
Vue.use(vClickOutside);

if (!isIE()) {
    Vue.component(
        'VDatePicker',
        () =>
            import(
                /* webpackChunkName: "date-picker" */ './components/molecules/datePicker/VDatePicker.vue'
            ),
    );
}
