import { JSONSchema7Definition } from 'json-schema';
import { defaults as _defaults } from 'lodash';
import {
    FormElement,
    FormElementConfig,
    formElementDefaultConfig,
} from '../formElement';

export interface FormFieldConfig<TValue> extends FormElementConfig {
    value?: TValue;
}

export const formFieldDefaultConfig = {
    ...formElementDefaultConfig,
    value: null,
};

export class FormField<TValue> extends FormElement {
    public value: TValue | null;

    public constructor(config?: FormFieldConfig<TValue>) {
        const resolvedConfig = _defaults(
            config || ({} as FormFieldConfig<TValue>),
            formFieldDefaultConfig,
        );

        super(resolvedConfig);
        this.value = resolvedConfig.value ?? null;
    }

    public getJsonSchema(): JSONSchema7Definition {
        return {};
    }

    public isValid(): boolean {
        return Object.values(this.validators).every((validator) => {
            if (typeof validator === 'function') {
                return validator(this.value);
            }
            return false;
        });
    }
}
