import { PhotovoltaicFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/photovoltaicContent';

export const photovoltaicContent: PhotovoltaicFormGroupContent = {
    pvHeadline: {
        text: 'PV-Module',
    },
    pvQuantityAndNominalPower: {
        label: 'Anzahl und Nennleistung',
        quantity: {},
        nominalPower: {
            placeholder: '260',
            unit: 'W',
        },
    },
    pvTotalNominalPower: {
        label: 'Summe Nennleistung',
        unit: '{{ value }} kW',
    },
    pvAddRemovePvModule: {
        addLabel: 'PV-Modul hinzuf&uuml;gen',
        removeLabel: 'PV-Modul entfernen',
    },
    pvOverallNominalPower: {
        label: 'Summe Nennleistung PV-Module',
        unit: '{{ value }} kW',
    },
};
