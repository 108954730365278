


























import Vue, { PropType } from 'vue';
import { LinkHoverBehavior } from '@/enums/linkHoverBehavior';
import AppRouterLink from '@/components/atoms/appRouterLink/AppRouterLink.vue';
import { ButtonTheme } from '@/enums/buttonTheme';

export default Vue.extend({
    name: 'AppLink',
    components: {
        AppRouterLink,
    },
    props: {
        url: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        hoverBehavior: {
            type: String as PropType<LinkHoverBehavior>,
            default: LinkHoverBehavior.default,
        },
        withRightAngleQuote: {
            type: Boolean,
            default: false,
        },
        renderAsButton: {
            type: Boolean,
            default: false,
        },
        buttonTheme: {
            type: String as PropType<ButtonTheme>,
            default: ButtonTheme.buttonPrimary,
        },
    },
    computed: {
        isRootLink(): boolean {
            return this.url == '/';
        },
        buttonThemeClass(): ButtonTheme | null {
            return this.renderAsButton ? this.buttonTheme : null;
        },
    },
});
