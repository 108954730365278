import { StorageFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/storageContent';

export const storageContent: StorageFormGroupContent = {
    storageHeadline: {
        text: 'Speicher (Batterie)',
    },
    storageManufacturerAndStorageType: {
        label: 'Hersteller und Typ',
        manufacturer: {
            placeholder: 'EW Speicher GmbH',
        },
        storageType: {
            placeholder: 'S4 E Pro',
        },
    },
    storageQuantity: {
        label: 'Anzahl',
    },
    storageDischargePowerAndStorageCapacity: {
        label: 'Nennentladeleistung und nutzbare Speicherkapazität',
        dischargePower: {
            placeholder: '2',
            unit: 'kW',
        },
        storageCapacity: {
            placeholder: '5',
            unit: 'kWh',
        },
    },
    storageTotalDischargePower: {
        label: 'Summe Nennentladeleistung',
        unit: '{{ value }} kW',
    },
    storageTotalStorageCapacity: {
        label: 'Summe nutzbare Speicherkapazität',
        unit: '{{ value }} kWh',
    },
    storageAddRemoveModule: {
        addLabel: 'Speicher (Batterie) hinzufügen',
        removeLabel: 'Speicher (Batterie) entfernen',
    },
    storageOverallDischargePower: {
        label: 'Gesamtergebnis aus Summe Nennentladeleistung',
        unit: '{{ value }} kW',
    },
    storageOverallStorageCapacity: {
        label: 'Gesamtergebnis aus Summe nutzbare Speicherkapazität',
        unit: '{{ value }} kWh',
    },
};
