import type { ApiClientInterface } from './interfaces/apiClient';
import { ApiClient as ApiClientServer } from './server/apiClient';
import { ApiClient as ApiClientMock } from './mock/apiClient';
import { ApiClientType } from '@/enums/apiClientType';

const envApiClient = process.env.VUE_APP_API_CLIENT;
let apiClient: ApiClientInterface;
switch (envApiClient) {
    case ApiClientType.server:
        apiClient = new ApiClientServer();
        break;
    default:
        apiClient = new ApiClientMock();
        break;
}

export const {
    configurationRepository,
    contentRepository,
    applicationRepository,
    adminRepository,
    addressRepository,
    addressesRepository,
    fileRepository,
    documentRepository,
    toolsRepository,
    mkfRepository,
} = apiClient;
