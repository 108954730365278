










import Vue from 'vue';
import { OverlayColors } from '@/enums/overlayColors';

export default Vue.extend({
    name: 'BackgroundOverlay',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        overlayColor: {
            type: String,
            default: OverlayColors.tertiary,
        },
    },
});
