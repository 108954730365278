import { MessageDialogueActions } from '@/enums/messageDialogueActions';
import { MessageType } from '@/enums/messageType';
import Vue from 'vue';
import { InterpolationPlaceholders } from './interpolationService';
import { messageService } from './messageService';

export interface Dialogue {
    actions: MessageDialogueActions;
    message: string;
    visible: boolean;
}

class DialogueMessageService {
    private readonly state = Vue.observable({
        dialogue: {
            actions: MessageDialogueActions.close,
            message: '',
            visible: false,
        } as Dialogue,
    });

    private async getDialogueMessage(
        identifier: string,
        messageType: MessageType,
        placeholders: InterpolationPlaceholders,
    ): Promise<string> {
        if (messageType === MessageType.error) {
            return messageService.getErrorMessage(identifier, placeholders);
        }

        return messageService.getInfoMessage(identifier, placeholders);
    }

    public async show(
        identifier: string,
        messageType: MessageType,
        actions?: MessageDialogueActions,
        placeholders: InterpolationPlaceholders = {},
    ): Promise<void> {
        this.state.dialogue.message = await this.getDialogueMessage(
            identifier,
            messageType,
            placeholders,
        );

        if (actions) {
            this.state.dialogue.actions = actions;
        }

        this.state.dialogue.visible = true;
    }

    public hide(): void {
        this.state.dialogue.message = '';
        this.state.dialogue.visible = false;
    }

    get actions(): MessageDialogueActions {
        return this.state.dialogue.actions;
    }

    get message(): string {
        return this.state.dialogue.message;
    }

    get visibility(): boolean {
        return this.state.dialogue.visible;
    }
}

export const dialogueMessageService = new DialogueMessageService();
