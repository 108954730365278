import { staticImplements } from '@/decorators/staticImplements';
import { httpClient } from '../httpClient/httpClient';
import { MkfRepositoryInterface } from '@/api/interfaces/mkf/mkfRepositoryInterface';
import { Mkf } from '@/api/interfaces/mkf/mkf';

@staticImplements<MkfRepositoryInterface>()
export class MkfRepository {
    static async getMkfData(): Promise<Mkf> {
        return httpClient.get<Mkf>(`/api/dea/v1/external/mkf`);
    }
}
