import { MockService } from '../mockService';
import addressSuggestionsDefault from './data/addressSuggestionsDefault.json';
import addressSuggestionsEmpty from './data/addressSuggestionsEmpty.json';
import addressSuggestionsThreeChars from './data/addressSuggestionsThreeChars.json';
import addressSuggestionsFourChars from './data/addressSuggestionsFourChars.json';
import addressSuggestionsFiveChars from './data/addressSuggestionsFiveChars.json';
import type { AddressRepositoryInterface } from '@/api/interfaces/address/addressRepository';
import { staticImplements } from '@/decorators/staticImplements';
import { AddressSuggestions } from '@/api/interfaces/address/addressSuggestions';

@staticImplements<AddressRepositoryInterface>()
export class AddressRepository {
    static async fetchSuggestions(
        order: string[],
        zip: string | null,
        /* eslint-disable @typescript-eslint/no-unused-vars */
        city: string | null,
        street: string | null,
        district: string | null,
        /* eslint-enable @typescript-eslint/no-unused-vars */
    ): Promise<AddressSuggestions> {
        let suggestions: AddressSuggestions;

        if (zip === null || zip.indexOf('999') !== -1) {
            suggestions = addressSuggestionsEmpty;
        } else {
            switch (zip.length) {
                case 5:
                    suggestions = addressSuggestionsFiveChars;
                    break;
                case 4:
                    suggestions = addressSuggestionsFourChars;
                    break;
                case 3:
                    suggestions = addressSuggestionsThreeChars;
                    break;
                default:
                    suggestions = addressSuggestionsDefault;
            }
        }

        const mockService = new MockService<AddressSuggestions>();
        return mockService.runServiceWithSuccess(
            suggestions,
            5, // wait a bit before returning results
        );
    }
}
