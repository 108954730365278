import { ApplicationForm } from './specific/applicationForm';
import { KitchenSinkForm } from './dummy/kitchenSinkForm';

export class Forms {
    public readonly kitchenSink: KitchenSinkForm;
    public readonly application: ApplicationForm;

    public constructor(config: Forms) {
        this.kitchenSink = config.kitchenSink;
        this.application = config.application;
    }
}
