import { HeaderContent } from '@/api/interfaces/content/app/headerContent';

export const headerContent: HeaderContent = {
    logoContent: {
        linkUrl: '/',
        image: {
            path:
                'https://deaapi-local.ewe.de/-/media/dea/bilder/marke/logo-ewe-netz.svg?cb=406F5B98',
            alt: 'Ewe Netz Logo',
            title: 'Ewe Netz Logo',
        },
    },
    metaNavigationContent: {
        adminLinkText: 'Admin',
        toolsLinkText: 'Tools',
        toOverviewLinkText: 'Zur Übersicht',
        logoutLinkText: 'Logout',
    },
    mainNavigationItems: [
        {
            text: 'Privatkunden',
            url: 'https://www.ewe-netz.de/privatkunden',
            target: '_blank',
            title: 'Privatkunden',
        },
        {
            text: 'Geschäftskunden',
            url: 'https://www.ewe-netz.de/geschaeftskunden',
            target: '_blank',
            title: 'Geschäftskunden',
        },
        {
            text: 'Einspeiser',
            url: 'https://www.ewe-netz.de/einspeiser',
            target: '_blank',
            title: 'Einspeiser',
        },
        {
            text: 'Marktpartner',
            url: 'https://www.ewe-netz.de/marktpartner',
            title: 'Marktpartner',
        },
        {
            text: 'Kommunen',
            url: 'https://www.ewe-netz.de/kommunen',
            title: 'Kommunen',
        },
    ],
};
