var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.label !== null)?_c('button',{staticClass:"button focus:outline-none inline relative leading-6 py-4 px-4 rounded uppercase",class:[
        {
            'include-character': _vm.includeCharacter,
            'include-character-left': _vm.includeCharacterLeft,
            'include-character-right': _vm.includeCharacterRight,
        },
        _vm.includeCharacter ? _vm.character : '',
        _vm.theme ],on:{"click":function($event){return _vm.$emit('button-click')}}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }