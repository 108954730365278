import { DocumentsFormStepContent } from '@/api/interfaces/content/form/steps/documentsFormStepContent';

export const documentsContent: DocumentsFormStepContent = {
    stepHeading: 'Dokumente',
    buttonNext: {
        text: 'Weiter',
    },
    meterPicture: {
        label: 'Foto vom Stromzähler',
        subLabel: '(.pdf oder .jpg, bis 5 MB)',
    },
    bafaApproval: {
        label:
            'BAFA-Zulassungsbescheid bzw. die Bescheinigung &uuml;ber die elektronische Anzeige f&uuml;r KWK-Anlagen bis 50 kWel',
        subLabel: '(.pdf oder.jpg, bis 5 MB)',
        summaryLabel:
            'BAFA-Zulassungsbescheid bzw. die Bescheinigung &uuml;ber die elektronische Anzeige',
    },
    gridAndPlantProtectionProofOfConformity: {
        label: 'Zertifikat zentraler NA-Schutz &gt; 30 kVA',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    pvManufacturerDataSheet: {
        label: 'Datenblatt Modulhersteller',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    inverterConformityConfirmationOrUnitCertificate: {
        label: 'Einheitenzertifikat Wechselrichter',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    inverterTechnicalDataSheet: {
        label: 'Technisches Datenblatt Wechselrichter',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    inverterProofOfConformity: {
        label: 'Zertifikat Wechselrichter integrierter NA-Schutz',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    inverterUnitCertificate: {
        label: 'Einheitenzertifikat Wechselrichter',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    storageConformityDeclarationStorageSystem: {
        label: 'Einheitenzertifikat Speichersystem',
        subLabel: '.pdf oder.jpg, \nbis 5 MB',
        info:
            'siehe FNN Hinweis "Anschluss und Betrieb von Speicher am Niederspannungsnetz"',
    },
    storageManufacturerDataSheet: {
        label: 'Technisches Datenblatt Speicherhersteller',
        subLabel: '.pdf oder.jpg, bis 5 MB',
    },
    generatorTechnicalDataSheet: {
        label: 'Technisches Datenblatt der Erzeugungseinheit',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    generatorConformityConfirmationOrUnitCertificate: {
        label: 'Einheitenzertifikat Erzeugungseinheit',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    generatorProofOfConformity: {
        label: 'Zertifikat integrierter NA-Schutz',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    generatorUnitOrPrototypeConfirmation: {
        label:
            'Einheitenzertifikat oder Prototypenbest&auml;tigung der Erzeugungseinheit',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    generatorComponentCertificate: {
        label:
            '<span style="color: #464646;">Wenn erforderlich: Komponentenzertifikat der Erzeugungseinheit</span>',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
    },
    performanceMonitoringCertificate: {
        label: 'Zertifikat für die Leistungsüberwachung',
        subLabel: '(.pdf, bis 5 MB)',
    },
    sitePlanWithLotNumber: {
        label: 'Lageplan mit Flurstücksnummer',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
        info:
            'Aus dem Lageplan müssen die Bezeichnung und die Grenzen des Grundstücks sowie der Aufstellungsort der Erzeugungsanlage hervorgehen.',
    },
    electricalSystemCircuitDiagram: {
        label: 'Übersichtsschaltplan der elektrischen Anlage',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
        info:
            'Übersichtsschaltplan des Anschlusses der Erzeugungsanlage einschließlich bereits vorhandener Anlagen und kundeneigener Betriebsmittel inklusive Anordnung der Mess- und Schutzeinrichtung.',
    },
    proofOfReadyForPlanning: {
        label: 'E2 Datenblatt zur Beurteilung der Netzrückwirkung',
        subLabel: '(.jpg oder .pdf, bis 5 MB)',
        info:
            'Mögliche Nachweise: <ul><li>Ein Foto/eine Datei der Baugenehmigung oder eine Eingangsbestätigung über die Beantragung der Baugenehmigung</li><li>Ein Foto/eine Datei des Bebauungsplans (B-Plan)</li></ul>',
    },
};
