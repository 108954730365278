import type { ApiClientInterface } from '@/api/interfaces/apiClient';
import { ContentRepository } from './content/contentRepository';
import { ApplicationRepository } from './application/applicationRepository';
import { ContentRepositoryInterface } from '../interfaces/content/contentRepositoryInterface';
import { ApplicationRepositoryInterface } from '../interfaces/application/applicationRepository';
import { AddressRepositoryInterface } from '../interfaces/address/addressRepository';
import { AddressRepository } from './address/addressRepository';
import { FileRepositoryInterface } from '../interfaces/file/fileRepositoryInterface';
import { FileRepository } from './file/fileRepository';
import { DocumentRepositoryInterface } from '../interfaces/document/documentRepositoryInterface';
import { DocumentRepository } from './document/documentRepository';
import { ConfigurationRepository } from './configuration/configurationRepository';
import { ConfigurationRepositoryInterface } from '../interfaces/configuration/configurationRepositoryInterface';
import { AdminRepository } from './admin/adminRepository';
import { AdminRepositoryInterface } from '../interfaces/admin/adminRepository';
import { ToolsRepository } from '../server/tools/toolsRepository';
import { ToolsRepositoryInterface } from '../interfaces/tools/toolsRepository';
import { MkfRepository } from './mkf/mkfRepository';
import { MkfRepositoryInterface } from '../interfaces/mkf/mkfRepositoryInterface';
import { AddressesRepositoryInterface } from '../interfaces/address/addressesRepository';
import { AddressesRepository } from '../server/address/addressesRepository';

export class ApiClient implements ApiClientInterface {
    configurationRepository: ConfigurationRepositoryInterface;
    contentRepository: ContentRepositoryInterface;
    applicationRepository: ApplicationRepositoryInterface;
    adminRepository: AdminRepositoryInterface;
    addressRepository: AddressRepositoryInterface;
    addressesRepository: AddressesRepositoryInterface;
    fileRepository: FileRepositoryInterface;
    documentRepository: DocumentRepositoryInterface;
    toolsRepository: ToolsRepositoryInterface;
    mkfRepository: MkfRepositoryInterface;

    constructor() {
        this.configurationRepository = ConfigurationRepository;
        this.contentRepository = ContentRepository;
        this.applicationRepository = ApplicationRepository;
        this.adminRepository = AdminRepository;
        this.addressRepository = AddressRepository;
        this.addressesRepository = AddressesRepository;
        this.fileRepository = FileRepository;
        this.documentRepository = DocumentRepository;
        this.toolsRepository = ToolsRepository;
        this.mkfRepository = MkfRepository;
    }
}
