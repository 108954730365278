import { AddressesRepositoryInterface } from '@/api/interfaces/address/addressesRepository';
import { staticImplements } from '@/decorators/staticImplements';
import { httpClient } from '../httpClient/httpClient';

const addressesTimeout = 30000; // 30 sec

@staticImplements<AddressesRepositoryInterface>()
export class AddressesRepository {
    static async getZipSuggestions(zip: string): Promise<string[]> {
        return httpClient.get<string[]>(
            '/api/dea/v1/external/addresses/zipSuggestions',
            {
                params: {
                    zip,
                },
            },
            addressesTimeout,
        );
    }

    static async getCitySuggestionsByZip(zip: string): Promise<string[]> {
        return httpClient.get<string[]>(
            '/api/dea/v1/external/addresses/citySuggestionsByZip',
            {
                params: {
                    zip,
                },
            },
            addressesTimeout,
        );
    }

    static async getCitySuggestionsByCity(city: string): Promise<string[]> {
        return httpClient.get<string[]>(
            '/api/dea/v1/external/addresses/citySuggestionsByCity',
            {
                params: {
                    city,
                },
            },
            addressesTimeout,
        );
    }

    static async getStreetSuggestions(
        zip: string,
        city: string,
        street: string | undefined | null,
    ): Promise<string[]> {
        return httpClient.get<string[]>(
            '/api/dea/v1/external/addresses/streetSuggestions',
            {
                params: {
                    zip,
                    city,
                    street,
                },
            },
            addressesTimeout,
        );
    }

    static async getAvailability(
        zip: string,
        city: string,
        street: string,
    ): Promise<boolean> {
        return httpClient.get<boolean>(
            '/api/dea/v1/external/addresses/availability',
            {
                params: {
                    zip,
                    city,
                    street,
                },
            },
            addressesTimeout,
        );
    }
}
