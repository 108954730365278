// If changes to the response types have to be mad, then also update the error codes in Backend accordingly (AddressesConstants.cs)

export const ALL_ADDRESSES_API_BAD_RESPONSE_TYPES = [
    'badSuccess',
    'authorizationFailed',
    'serviceUnavailable',
    'unspecifiedError',
] as const;

export type AddressesApiBadResponse = typeof ALL_ADDRESSES_API_BAD_RESPONSE_TYPES[number];
