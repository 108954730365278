import { ServiceError } from '../interfaces/serviceError';

interface MockServiceInterface<T> {
    runServiceWithSuccess(mockData: T, milliseconds: number): Promise<T>;
    runServiceWithFailure(milliseconds: number): Promise<T>;
}

enum ServiceResultType {
    success,
    error,
}

export class MockService<T> implements MockServiceInterface<T> {
    private runService = (
        resultType: ServiceResultType,
        milliseconds: number,
        mockData?: T,
    ): Promise<T> => {
        return new Promise((resolve, reject) =>
            setTimeout(() => {
                switch (resultType) {
                    case ServiceResultType.success:
                        resolve(mockData);
                        break;
                    default:
                        reject({
                            messageCode: 'fatalError',
                            message: 'An error has occurred',
                        } as ServiceError);
                }
            }, milliseconds),
        );
    };

    public async runServiceWithSuccess(
        mockData: T,
        milliseconds: number,
    ): Promise<T> {
        return await this.runService(
            ServiceResultType.success,
            milliseconds,
            mockData,
        );
    }

    public async runServiceWithFailure(milliseconds: number): Promise<T> {
        return await this.runService(ServiceResultType.error, milliseconds);
    }
}
