import { MockService } from '../mockService';
import type { ConfigurationRepositoryInterface } from '@/api/interfaces/configuration/configurationRepositoryInterface';
import { staticImplements } from '@/decorators/staticImplements';
import { FormConfiguration } from '@/api/interfaces/configuration/form/formConfiguration';
import { applicationFormConfiguration } from './form/applicationFormConfiguration';
import { ApplicationFormConfiguration } from '@/api/interfaces/configuration/form/applicationFormConfiguration';

@staticImplements<ConfigurationRepositoryInterface>()
export class ConfigurationRepository {
    static fetchFormConfiguration(): Promise<FormConfiguration> {
        const content: FormConfiguration = {
            applicationFormConfiguration: applicationFormConfiguration as ApplicationFormConfiguration,
        };

        const mockService = new MockService<FormConfiguration>();
        return mockService.runServiceWithSuccess(content, 5);
    }
}
