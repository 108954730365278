














export default {
    name: 'AppContent',
    props: {
        content: {
            type: String,
            default: null,
        },
        useLargeFont: {
            type: Boolean,
            default: false,
        },
    },
};
