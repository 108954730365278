










import Vue from 'vue';

export default Vue.extend({
    name: 'HamburgerMenu',
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            isActive: false,
        };
    },
    watch: {
        open: function () {
            this.isActive = this.open;
        },
    },
    methods: {
        toggleMenu() {
            this.isActive = !this.isActive;
            this.$emit('toggle-menu');
        },
    },
});
