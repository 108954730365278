export const EPSILON = Number?.EPSILON ?? 0;

export function toFixed(fractionDigits: number): (value: number) => number {
    const factor = Math.pow(10, fractionDigits);

    return function (value: number): number {
        return Math.round((value + EPSILON) * factor) / factor;
    };
}

export function roundToDecimals(value: number, decimals = 2): number {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
}
