import { EntryListContent } from '@/api/interfaces/content/common/entryListContent';

export const enumsContent: EntryListContent = {
    applicationStatus: {
        draft: 'In Erfassung',
        sent: 'Antrag versendet',
        inReview: 'Antrag in Prüfung',
        incomplete: 'Antrag unvollständig',
        inProgress: 'Antrag in Bearbeitung',
        proposalSent: 'Angebot versendet',
    },
    plantType: {
        emergencyGenerator: '',
        fuelCell: 'Brennstoffzelle',
        gasTurbine: 'Gasturbine',
        hydroPowerPlant: 'Wasserkraftanlage',
        orcPowerPlant: 'ORC-Anlage',
        photovoltaicPowerPlant: 'Solaranlage',
        steamTurbine: 'Wasserdampfturbine',
        stirlingEngine: 'Stirling-Motor',
        storage: 'Speicher',
        thermalPowerPlantDea: 'Blockheizkraftwerk',
        thermalPowerPlantKwk: 'Blockheizkraftwerk',
        windPowerPlant: 'Windenergieanlage',
    },
    technology: {
        bio: 'Bio',
        emergencyGenerator: 'Notstromaggregat > 100 ms',
        fuelCell: 'Brennstoffzelle',
        gasTurbine: 'Gasturbine',
        hydroPowerPlant: 'Wasserkraftanlage',
        orcPowerPlant: 'ORC-Anlage',
        photovoltaicPowerPlant: 'Solaranlage',
        steamTurbine: 'Wasserdampfturbine',
        stirlingEngine: 'Stirling-Motor',
        storage: 'Speicher',
        thermalPowerPlantDea: 'Blockheizkraftwerk',
        thermalPowerPlantKwk: 'Blockheizkraftwerk',
        windPowerPlant: 'Windenergieanlage',
    },
};
