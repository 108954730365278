import { MockService } from '../mockService';
import type { AdminRepositoryInterface } from '@/api/interfaces/admin/adminRepository';
import { staticImplements } from '@/decorators/staticImplements';
import { ServiceSuccess } from '@/api/interfaces/serviceSuccess';
import { AdminGetApplicationsResponse } from '@/api/interfaces/admin/adminGetApplicationsResponse';
import adminGetApplicationsResponseMockData from './data/adminGetApplicationsResponse.json';
import { DocumentDownloadResponse } from '@/api/interfaces/document/documentDownloadResponse';
import { AdminDocumentId } from '@/api/interfaces/admin/adminDocumentId';
import { AdminExportType } from '@/api/interfaces/admin/adminExportType';
import { AdminUpdateDataModel } from '@/types/app/admin/adminUpdateDataModel';

@staticImplements<AdminRepositoryInterface>()
export class AdminRepository {
    static fetchApplications(): Promise<AdminGetApplicationsResponse> {
        const mockService = new MockService<AdminGetApplicationsResponse>();
        return mockService.runServiceWithSuccess(
            adminGetApplicationsResponseMockData,
            5,
        );
    }

    static deleteApplication(applicationId: string): Promise<ServiceSuccess> {
        const mockService = new MockService<ServiceSuccess>();

        // fail in 25% of the cases to showcase failures
        if (
            applicationId.endsWith('4') ||
            applicationId.endsWith('5') ||
            applicationId.endsWith('6') ||
            applicationId.endsWith('7')
        ) {
            return mockService.runServiceWithFailure(5);
        }

        return mockService.runServiceWithSuccess({ message: 'OK' }, 5);
    }

    static async downloadDocument(
        documentId: AdminDocumentId,
        applicationId: string,
    ): Promise<DocumentDownloadResponse> {
        const mockService = new MockService<DocumentDownloadResponse>();

        const response: DocumentDownloadResponse = {
            fileName: `Dummy file ${documentId}`,
            bytes: btoa(
                `File-ID: ${documentId}, Application-ID: ${applicationId}`,
            ),
        };

        return mockService.runServiceWithSuccess(response, 1000);
    }

    static migrateApplicant(applicationIds: string[]): Promise<ServiceSuccess> {
        const mockService = new MockService<ServiceSuccess>();

        // fail in 50% of the cases to showcase failures
        if (applicationIds.length % 2 == 0) {
            return mockService.runServiceWithFailure(5);
        }

        return mockService.runServiceWithSuccess({ message: 'OK' }, 5);
    }

    static async export(
        exportType: AdminExportType,
        adminUpdateData: AdminUpdateDataModel,
    ): Promise<DocumentDownloadResponse> {
        const mockService = new MockService<DocumentDownloadResponse>();

        const response: DocumentDownloadResponse = {
            fileName: `Dummy file ${exportType}.txt`,
            bytes: btoa(
                `File-ID: ${exportType}, ${JSON.stringify(adminUpdateData)}}`,
            ),
        };

        return mockService.runServiceWithSuccess(response, 1000);
    }
}
