import Vue from 'vue';
import { contentRepository } from '@/api';
import type { AppContent } from '@/api/interfaces/content/app/appContent';
import type { FormContent } from '@/api/interfaces/content/form/formContent';

class ContentService<T> {
    private state = Vue.observable({
        currentPromise: null as Promise<T> | null,
        loadedContent: null as T | null,
    });

    public constructor(private readonly getContentPromise: () => Promise<T>) {}

    public get hasContentLoaded(): boolean {
        return !!this.state.loadedContent;
    }

    public get isContentLoading(): boolean {
        return !this.state.loadedContent && !this.state.currentPromise;
    }

    public get content(): T | null {
        this.requestContentOnce();
        return this.state.loadedContent;
    }

    public async requestContentOnce(): Promise<T> {
        if (this.state.loadedContent) {
            return this.state.loadedContent;
        }

        if (this.state.currentPromise) {
            return await this.state.currentPromise;
        }

        return await this.requestContent();
    }

    public async requestContent(): Promise<T> {
        this.state.currentPromise = this.getContentPromise();
        this.state.loadedContent = await this.state.currentPromise;
        this.state.currentPromise = null;
        return this.state.loadedContent;
    }
}

export const contentServices = {
    app: new ContentService<AppContent>(() =>
        contentRepository.fetchAppContent(),
    ),
    form: new ContentService<FormContent>(() =>
        contentRepository.fetchFormContent(),
    ),
};
