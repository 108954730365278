import { DocumentsFormStepConfiguration } from '@/api/interfaces/configuration/form/steps/documentsFormStepConfiguration';

export const documentsConfiguration: DocumentsFormStepConfiguration = {
    meterPicture: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'meterPictureGuid',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    bafaApproval: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: '12bf1d0a-95ee-452b-8e06-bfee8081624b',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    pvManufacturerDataSheet: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'pvManufacturerDataSheetGuid',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    inverterConformityConfirmationOrUnitCertificate: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'inverterConformityConfirmationOrUnitCertificateGuid',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    inverterTechnicalDataSheet: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'inverterTechnicalDataSheetGuid',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    inverterProofOfConformity: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'inverterProofOfConformityGuid',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    inverterUnitCertificate: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'inverterUnitCertificateGuid',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    storageConformityDeclarationStorageSystem: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'c27e86de-1b8d-451f-8f92-be9ff8899ac5',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    storageManufacturerDataSheet: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: '92b4f24f-fd4f-4b25-8d57-08f854ec7a73',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    generatorTechnicalDataSheet: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: '6f3df1c3-786e-4232-b833-40bb2b73d7ab',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    generatorConformityConfirmationOrUnitCertificate: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: '66bf2738-2b41-47be-96ab-c8cc88d155d4',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    generatorProofOfConformity: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'fbf9c0b1-f5de-4adb-81f8-e94a7623f658',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    generatorUnitOrPrototypeConfirmation: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'd9633f59-013c-4f11-87da-915da5ddecec',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    generatorComponentCertificate: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: '94dea4f2-9fe5-4c5c-98af-4cc3d96d090a',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    gridAndPlantProtectionProofOfConformity: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'baaf7460-9664-4e95-813b-d785e6be0c40',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    performanceMonitoringCertificate: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'performanceMonitoringCertificateGuid',
                validations: {
                    fileTypes: ['pdf'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    sitePlanWithLotNumber: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'sitePlanWithLotNumberGuid',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    electricalSystemCircuitDiagram: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'electricalSystemCircuitDiagramGuid',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
    proofOfReadyForPlanning: {
        configurations: [
            {
                type: 'fileUploadConfiguration',
                scId: 'proofOfReadyForPlanningGuid',
                validations: {
                    fileTypes: ['pdf', 'jpg'],
                    maxFileSize: 5242880,
                },
            },
        ],
    },
};
