import { AdminContent } from '@/api/interfaces/content/app/adminContent';

export const adminContent: AdminContent = {
    stageContent: {
        icon: 'bleistift',
        heading: 'Admin',
    },
    adminGridContent: {
        columns: {
            applicantId: {
                name: 'Antragssteller-ID',
            },
            applicationId: {
                name: 'Antrags-ID',
            },
            applicationNumber: {
                name: 'Antrags&shy;nummer',
            },
            completed: {
                name: 'Vollständig',
            },
            created: {
                name: 'Erstellt',
            },
            dataVersion: {
                name: 'Datenversion',
            },
            status: {
                name: 'Status',
            },
            submitted: {
                name: 'Versandt',
            },
        },
        noApplicationsText: 'Keine Antr&auml;ge wurden gefunden.',
        deleteButtonText: 'Löschen',
        editButtonText: 'Bearbeiten',
        downloadDocumentsButtonText: 'Antragsdokumente herunterladen',
        downloadCsvButtonText: 'CSV herunterladen',
        downloadJsonButtonText: 'JSON herunterladen',
        downloadDataButtonText: 'Daten als JSON herunterladen',
        dataApplyMigrationsText: 'Migrationen anwenden',
        migrateApplicantButtonText: 'Antragssteller umziehen',
        migrateApplicantTargetApplicantIdLabel: 'Id des Ziel-Antragsstellers',
        exportButtonText: 'Export',
        adminPagination: {
            paginationTextFirst: '<<<',
            paginationTextLast: '>>>',
            paginationTextCount:
                'Zeige {from} bis {to} von {count} Anträgen|{count} Anträge|Ein Antrag',
            filterResetButtonText: 'Reset',
            filterQuery: {
                placeholder: 'ID / Nummer',
            },
            filterDateSentAtStart: {
                placeholder: 'Versandt von',
            },
            filterDateSentAtEnd: {
                placeholder: 'Versandt bis',
            },
            filterStatus: {
                placeholder: 'Status',
            },
        },
    },
    deleteApplicationConfirmationLayer: {
        confirmationText:
            'Sind Sie sicher? Der Antrag wird unwiderruflich gelöscht.',
        buttonAccept: {
            text: 'Ja, ich möchte löschen',
        },
        buttonCancel: {
            text: 'Abbrechen',
        },
    },
    migrateApplicantLayer: {
        confirmationText:
            "Für diese ${this.migrateApplicantApplicationIds.length} Anträge wird der Antragssteller umgezogen: <ul><li>${this.migrateApplicantApplicationIds.join('</li><li>')}</li></ul><br>",
        buttonAccept: {
            text: 'Antragssteller umziehen',
        },
        buttonCancel: {
            text: 'Abbrechen',
        },
    },
    exportLayer: {
        confirmationText: '${this.paginationTotal} Anträge exportieren?',
        buttonAccept: {
            text: 'Exportieren',
        },
        buttonCancel: {
            text: 'Abbrechen',
        },
    },
};
