

































import Vue from 'vue';
import { ColorScheme } from '@/enums/colorScheme';
import { Align } from '@/enums/align';
import { Orientation } from '@/enums/orientation';
import AppTitle from '@/components/atoms/appTitle/AppTitle.vue';
import AppRouterLink from '@/components/atoms/appRouterLink/AppRouterLink.vue';
import LinkList from '@/components/molecules/linkList/LinkList.vue';
import { authService } from '@/services/authService';

export default Vue.extend({
    name: 'SideNavigation',
    components: {
        AppTitle,
        LinkList,
        AppRouterLink,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        sideNavigationItems: {
            type: Array,
            default: () => [],
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        adminLinkText: {
            type: String,
            default: null,
        },
        toOverviewLinkText: {
            type: String,
            default: null,
        },
    },
    data: () => {
        return {
            colorScheme: ColorScheme.secondary,
            align: Align.center,
            orientation: Orientation.vertical,
        };
    },
    computed: {
        showAdminLink(): boolean {
            return (
                authService.isAuthenticated && authService.hasRoles('DEA Admin')
            );
        },
    },
});
