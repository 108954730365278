





























import Vue from 'vue';
import BackgroundOverlay from '@/components/atoms/backgroundOverlay/BackgroundOverlay.vue';
import { OverlayColors } from '@/enums/overlayColors';

export default Vue.extend({
    name: 'AppLayer',
    components: {
        BackgroundOverlay,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            overlayColor: OverlayColors.background,
        };
    },
    methods: {
        hasSlot(name: string): boolean {
            return !!this.$scopedSlots[name];
        },
    },
});
