import { LocationFormStepContent } from '@/api/interfaces/content/form/steps/locationFormStepContent';

export const locationContent: LocationFormStepContent = {
    stepHeading: 'Standort',
    buttonNext: {
        text: 'Weiter',
    },
    stepIntro: {
        text:
            'Bitte geben Sie hier Daten zum Standort Ihrer neuen Erzeugungsanlage ein.',
    },
    zipAndCity: {
        label: 'PLZ und Ort',
        zip: {
            placeholder: 'PLZ',
        },
        city: {
            placeholder: 'Ort',
        },
    },
    streetAndHousenumber: {
        label: 'Straße und Hausnummer',
        street: {
            placeholder: 'Straße',
        },
        housenumber: {
            placeholder: 'Nr.',
        },
    },
    addressCheckFeedbackCheckingHeadline: {
        text: 'Die Adressprüfung wird durchgeführt...',
    },
    addressCheckFeedbackAvailableHeadline: {
        text: 'Die Adresse liegt in unserem Netzgebiet.',
    },
    addressCheckFeedbackEnterAddressHeadline: {
        text: 'Bitte die Adresse vollständig ausfüllen.',
    },
    addressCheckFeedbackUnavailableHeadline: {
        text: 'Wir konnten diese Adresse nicht in unserem Netzgebiet finden.',
    },
    addressCheckFeedbackUnavailableInfoText: {
        text:
            '<ul>\n    <li>Bitte überprüfen Sie Ihre Eingaben.</li>\n    <li>Prüfen Sie über <a href="https://www.vnbdigital.de/">VNBdigital</a>, ob EWE NETZ ihr zuständiger Netzbetreiber ist.</li>\n    <li>Sind Ihre Angaben korrekt aber die Straße wird nicht angezeigt? Ergänzen Sie bitte weitere Adressdaten (Gemarkung).</li>\n</ul>',
    },
    proceedWithCadastral: {
        label: 'Ich möchte den Anlagenstandort über die Gemarkung angeben',
    },
    subdistrict: {
        label: 'Gemarkung',
        placeholder: 'Gemarkung',
        info:
            'Falls dem Grundstück noch keine Adresse\nzugeordnet ist, geben Sie bitte nähere Informationen zu Gemarkung, Flur und Flurstück ein.\nDie Angaben finden Sie auf Ihrer Liegenschaftskarte. Die Gemarkung entspricht meistens dem Namen der Gemeinde.',
    },
    meadowAndLot: {
        label: 'Flur und Flurstück',
        meadow: {
            placeholder: 'Flur',
        },
        lot: {
            placeholder: 'Flurstück',
            info:
                'Der Flur (Gelände). Die Bezeichnung besteht in der Regel aus einer Kombination von zwei Zahlen (z.B. 120).<br><br>Die Flurstücksnummer besteht aus einer Kombination von Zahl und Buchstabe (z.B. 234 a) oder einer Kombination zweier Zahlen (z.B. 234/34)',
        },
    },
    building: {
        label: 'Gebäude, Gebäudeteil',
        placeholder: 'Gebäude, Gebäudeteil',
        info:
            'Nähere Angaben zum Anlagenstandort - z.B. "Wohnhaus", "Halle", "Carport" oder "Stall".',
    },
    mapHeadline: {
        text: 'Wo ist der Standort Ihrer Solaranlage?',
    },
    mapHint: {
        text:
            'Sie können den gelben Marker im Luftbild verschieben. Bitte setzen Sie den gelben Marker auf die Mitte der Fläche, auf der sich Ihre Anlage befindet. Dadurch ändern sich die Geokoordinaten, nicht aber die Adresse.',
    },
    mapConsentHeadline: {
        text: 'Externe Inhalte aktivieren',
    },
    mapConsentText: {
        text:
            'An dieser Stelle finden Sie einen externen Dienst von Google, der Ihnen bei der richtigen Positionierung der Anlage helfen soll.',
    },
    mapConsentEnabled: {
        label: 'Google Map ist aktiviert',
    },
    mapConsentDisabled: {
        label: 'Google Map ist deaktiviert',
    },
    mapConsentHint: {
        text:
            'Ich bin damit einverstanden, dass mir externe Inhalte angezeigt werden. Damit können personenbezogene Daten an Drittplattformen übermittelt werden. Mehr dazu in unserer <a href="https://www.ewe-netz.de/meta/datenschutz" target="_blank">Datenschutzerklärung</a>.',
    },
    geographicLatCoordinates: {
        label: 'Geografische B Koordinaten',
        info:
            'Geographische Daten werden in sechzig Minuten, 1 Minute wiederum in 60 Sekunden angegeben (Beispiel 54°47\'19,96")',
        degrees: {
            placeholder: '53',
            unit: '°',
        },
        minutes: {
            placeholder: '6',
            unit: "'",
        },
        seconds: {
            placeholder: '43,4',
            unit: '"',
        },
    },
    geographicLngCoordinates: {
        label: 'Geografische L Koordinaten',
        info:
            'Geographische Daten werden in sechzig Minuten, 1 Minute wiederum in 60 Sekunden angegeben (Beispiel 54°47\'19,96")',
        degrees: {
            placeholder: '53',
            unit: '°',
        },
        minutes: {
            placeholder: '6',
            unit: "'",
        },
        seconds: {
            placeholder: '43,4',
            unit: '"',
        },
    },
};
