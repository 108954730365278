import { ThankYouContent } from '@/api/interfaces/content/app/thankYouContent';

export const thankYouContent: ThankYouContent = {
    stageContent: {
        icon: 'formular',
        heading: 'Eingangsbestätigung',
        content: '',
    },
    introContent: {
        heading: 'Vielen Dank!',
        content:
            '<p>Vielen Dank für Ihre Anmeldung zum Anschluss einer Eigenerzeugungsanlage an unser Netz. Wir werden Ihre Anmeldung umgehend bearbeiten. Den Bearbeitungsstatus können Sie der Antragsübersicht entnehmen.</p>',
    },
    contactBoxContent: {
        heading: 'Sprechen wir zusammen.',
        content: 'Für den kostenlosen Kontakt mit uns wählen Sie einfach:',
        telephoneLinks: [
            {
                text: 'T 0800 3936389',
            },
            {
                text: 'F 0441 4808 1195',
            },
        ],
        url: '#',
        text: 'zum Kontaktformular',
        target: '',
        title: '',
    },
    thankYouButtonsContent: {
        newApplicationButtonText: 'Neuer Antrag',
        backToOverviewButtonText: 'Zur Übersicht',
    },
};
