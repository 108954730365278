<template>
    <nav class="nav">
        <LinkList
            :links="mainNavigationItems"
            :align="align"
            :orientation="orientation"
        />
    </nav>
</template>

<script>
import Vue from 'vue';
import { Align } from '@/enums/align';
import { Orientation } from '@/enums/orientation';
import LinkList from '@/components/molecules/linkList/LinkList.vue';

export default Vue.extend({
    name: 'MainNavigation',
    components: {
        LinkList,
    },
    props: {
        mainNavigationItems: {
            type: Array,
            default: () => [],
        },
    },
    data: () => {
        return {
            align: Align.center,
            orientation: Orientation.horizontal,
        };
    },
});
</script>

<style lang="postcss" scoped>
.nav {
    & >>> .list-item .link {
        @apply uppercase font-semibold px-2 py-4;
    }
}
</style>
