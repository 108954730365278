import axios, {
    AxiosResponse,
    AxiosInstance,
    AxiosError,
    AxiosRequestConfig,
} from 'axios';
import { HttpClientInterface } from './httpClientInterface';
import { HttpRequestOptions } from './httpRequestOptions';
import { ServiceError } from '@/api/interfaces/serviceError';
import { ServiceFault } from '@/api/interfaces/serviceFault';
import { authService } from '@/services/authService';
import router from '@/router';

type HttpMethod =
    | 'get'
    | 'delete'
    | 'head'
    | 'options'
    | 'post'
    | 'put'
    | 'patch'
    | 'purge'
    | 'link'
    | 'unlink';

const cancelToken = axios.CancelToken;

class HttpClient implements HttpClientInterface {
    public constructor(private readonly axiosInstance: AxiosInstance) {}

    private async request<T>(
        httpMethod: HttpMethod,
        url: string,
        options?: HttpRequestOptions,
        timeout = 0,
    ): Promise<T> {
        if (!options?.anonymous) {
            await authService.authenticate();
        }

        let cancelTimeout;

        const requestConfig = {
            method: httpMethod,
            url,
            headers: options?.headers,
            data: options?.data,
            params: options?.params,
        } as AxiosRequestConfig;

        if (timeout > 0) {
            const source = cancelToken.source();
            cancelTimeout = setTimeout(() => {
                source.cancel();
            }, timeout);
            requestConfig.cancelToken = source.token;
            requestConfig.timeout = timeout;
        }

        const response = await this.axiosInstance.request(requestConfig);

        if (timeout > 0) {
            clearTimeout(cancelTimeout);
        }

        return response.data as T;
    }

    public get<T>(
        url: string,
        options?: HttpRequestOptions,
        timeout?: 0,
    ): Promise<T> {
        return this.request('get', url, options, timeout);
    }

    public post<T>(
        url: string,
        options?: HttpRequestOptions,
        timeout?: 0,
    ): Promise<T> {
        return this.request('post', url, options, timeout);
    }

    public put<T>(
        url: string,
        options?: HttpRequestOptions,
        timeout?: 0,
    ): Promise<T> {
        return this.request('put', url, options, timeout);
    }

    public delete<T>(
        url: string,
        options?: HttpRequestOptions,
        timeout?: 0,
    ): Promise<T> {
        return this.request('delete', url, options, timeout);
    }
}

const axiosInstance = axios.create({
    baseURL: process.env?.VUE_APP_API_HOSTNAME,
});

// add bearer token to every request, if the user is authenticated
axiosInstance.interceptors.request.use((config) => {
    if (authService.isAuthenticated) {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers.Authorization = `Bearer ${authService.token}`;
    }
    return config;
});

// service faults (i.e. Service Unavailable) are handled directly in the interceptor, all other
// errors are represented as a service error to be handled by the consumer
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        if (error.response?.status === 503) {
            router.push('/wartung');

            return Promise.reject({
                messageCode: 'unavailable',
            } as ServiceFault);
        }

        const serviceError = {
            messageCode: error.response?.data?.messageCode ?? 'fatalError',
            message: error.message,
        } as ServiceError;

        return Promise.reject(serviceError);
    },
);

export const httpClient = new HttpClient(axiosInstance) as HttpClientInterface;
