import { MeasuringConceptsFormStepContent } from '@/api/interfaces/content/form/steps/measuringConceptsFormStepContent';

export const measuringConceptsContent: MeasuringConceptsFormStepContent = {
    stepHeading: 'Messkonzepte',
    buttonNext: {
        text: 'weiter',
    },
    operatingMode: {
        label: 'Betriebsweise',
        placeholder: 'Bitte wählen',
        options: {
            fullInfeed: {
                label: 'Volleinspeisung',
            },
            surplusInfeed: {
                label: 'Überschusseinspeisung',
            },
            zeroInfeed: {
                label:
                    'Nulleinspeisung (Speicher und keine Einspeisung in das öffentliche Netz)',
            },
            commercialTransfer: {
                label: 'Kaufmännisch-bilanzielle Weitergabe',
            },
        },
    },
    fullInfeedBonusHeadline: {
        text: '<strong>Volleinspeisebonus</strong>',
    },
    fullInfeedBonus: {
        label:
            'Ich habe mich/ wir haben uns zu der Regelung des § 48 Abs. 2a EEG (Erhöhung des anzulegenden Werts bei Volleinspeisung) und insbesondere den Voraussetzungen und Vor- bzw. Nachteilen der neuen Regelung informiert und beanspruche die Erhöhung. Ich teile/ wir teilen Ihnen hiermit mit, dass ich/ wir bis zum Ende der Förderdauer den gesamten im Kalenderjahr in meiner/ unserer Anlage erzeugten Strom mit Ausnahme des Stroms, der in der Solaranlage oder in deren Neben- und Hilfsanlagen zur Erzeugung von Strom im technischen Sinn verbraucht wird, nach § 48 Abs. 2a EEG in das Netz von EWE NETZ einspeisen werde(n).<br>Sollten sich gegenüber dieser Erklärung für das Folgejahr Änderungen ergeben, werde ich / wir diese fristgerecht, bis zum 30.11. des Vorjahres mitteilen.',
    },
    measuringDeviceStorage: {
        label: 'Messeinrichtung Speicher',
        info:
            'Wenn kein Speicherz&auml;hler ZS1 im Messkonzept dargestellt ist, muss dieser nicht f&uuml;r die Abrechnung vorgesehen werden. Wir k&ouml;nnen Ihnen den Z&auml;hler aber gerne auf Wunsch einbauen.',
    },
    meterNumber: {
        label: 'Z&auml;hlernummer',
        summaryLabel: 'Z&auml;hlernummer',
        placeholder: 'Zählernummer',
        info:
            'Die Z&auml;hlernummer finden Sie auf Ihrer Energielieferabrechnung oder auf dem Z&auml;hler. N&auml;here Erl&auml;uterungen finden Sie auch unter <a rel="noopener noreferrer" rel="noopener noreferrer" href="https://www.ewe-netz.de/privatkunden/strom/smart-meter/zaehleranleitungen" target="_blank" title="Zähleranleitungen">Z&auml;hleranleitungen</a>.<br />\r\n<img height="855" alt="Zweirichtungszähler Zählernummer" width="964" src="https://deaapi-local.ewe.de/-/media/dea/bilder/formular/erzeugungsart/zweirichtungszaehler-zaehlernummer.png?la=de-de&amp;cb=656711A7&amp;hash=D461E8DEB69297859C780F09B60B75BC" />',
    },
    measuringPointOperation: {
        label: 'Der Messstellenbetrieb soll erfolgen durch',
        info:
            'Nach dem Messstellenbetriebsgesetz (MsbG) ist EWE NETZ als grundzust&auml;ndiger Messstellenbetreiber verpflichtet, moderne Messeinrichtungen (Anlagenleistung bis 7 KW) und intelligente Messsysteme (Anlagenleistung gr&ouml;&szlig;er 7 KW) einzubauen. Sobald die technische Verf&uuml;gbarkeit nach &sect; 30 MsbG gegeben ist, werden wir Ihre Anlagen mit der neuen Technik ausstatten. Weitere Informationen finden Sie unter <a rel="noopener noreferrer" rel="noopener noreferrer" href="http://www.ewe-netz.de" target="_blank">www.ewe-netz.de</a>.',
        options: {
            ewe: {
                label: 'EWE NETZ Messeinrichtung für Einspeisung',
            },
            other: {
                label:
                    'einen anderen Messstellenbetreiber gemäß § 10 Abs. 1 EEG (MSB-ID)E',
            },
        },
    },
    measuringPointOperator: {
        label: 'Messstellenbetreiber',
        placeholder: 'Messstellenbetreiber',
        options: {},
        search: {
            placeholder: 'Filter',
            noResults: 'Keine Ergebnisse',
        },
    },
    priceSheetMeasuringPointOperation: {
        text:
            'Preisblatt Messstellenbetrieb EWE NETZ&nbsp;<a href="http://local.deaapi.ewe-netz.de/-/media/dea/dokumente/formular/preisblatt-msb.pdf?la=de-de&amp;hash=7E591590CD93B257050746C9E7A4FF89B37F61E2" target="_blank">herunterladen</a>',
    },
    selfGenerationQuantity: {
        label:
            'Die Eigen<strong>erzeugungs</strong>menge betr&auml;gt pro Jahr voraussichtlich ca.:',
        info:
            'Die erzeugte elektrische Energie in Kilowattstunden einer Stromerzeugungsanlage.',
        unit: 'kWh',
    },
    selfSupplyQuantity: {
        label:
            'Die Eigen<strong>versorgungs</strong>menge in kWh betr&auml;gt pro Jahr voraussichtlich ca.:',
        info:
            'Erzeugte elektrische Energie in Kilowattstunde (kWh), die eine Stromerzeugungsanlage erzeugt und in der Kundenanlage verbraucht wird.',
        unit: 'kWh',
    },
    remainingElectricityInfo: {
        text:
            'Etwaige nach der Eigenversorgung und/oder der Belieferung Dritter verbleibende Strommengen werden in das Netz von EWE NETZ eigespeist.',
    },
};
