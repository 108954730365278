import { EntryListContent } from '@/api/interfaces/content/common/entryListContent';

export const messagesContent: EntryListContent = {
    admin: {
        exportError: 'Exportieren fehlgeschlagen.',
        exportGenerateCsvError:
            'Generieren der Export-CSV-Datei fehlgeschlagen.',
        getAdminApplicationsError:
            'Ein Fehler ist aufgetreten. Die Anträge konnten nicht geladen werden.',
        migrateApplicantError: 'Umziehen des Antragsstellers fehlgeschlagen.',
        migrateApplicantRequirementsUnfulfilled:
            'Keine Anträge ausgewählt, oder Id des Ziel-Antragsstellers ungültig.',
        migrateApplicantSuccess: 'Antragssteller erfolgreich umgezogen.',
    },
    applications: {
        copied: 'Der Antrag wurde erfolgreich kopiert.',
        deleted: 'Der Antrag wurde erfolgreich gelöscht.',
        deleteError:
            'Ein Fehler ist aufgetreten. Der Antrag wurde nicht gelöscht.',
        getDataError:
            'Ein Fehler ist aufgetreten. Der Antrag konnte nicht geladen werden.',
        getUserApplicationsError:
            'Ein Fehler ist aufgetreten. Die Anträge konnten nicht geladen werden.',
        notFound: 'Der Antrag konnte nicht gefunden werden.',
        saveError:
            'Ein technischer Fehler ist aufgetreten, bitte versuchen Sie es erneut.',
    },
    fatalError:
        'Ein technischer Fehler ist aufgetreten, bitte versuchen Sie es erneut.',
    tools: {
        bakeApplicationSummariesError:
            'Ein Fehler ist aufgetreten. Die Dokumente konnten nicht gebacken werden.',
        deleteBakedApplicationSummariesError:
            'Ein Fehler ist aufgetreten. Die gebackenen Dokumente wurden nicht gelöscht.',
        getApplicationsWithoutBakedSummaryError:
            'Ein Fehler ist aufgetreten. Die Anträge ohne gebackenen Dokumente konnten nicht geladen werden.',
    },
    unauthorized:
        'Sie sind nicht berechtigt, die angeforderte Seite aufzurufen.',
};
