import { defaults as _defaults } from 'lodash';
import { RuleDecl } from 'vue/types/options';

export type FormElementConfig = {
    /** If true, form element will be hidden in the form, but will be written to dto, if `disabled` is false. */
    hidden?: boolean; // TODO not implemented everywhere!

    /** If true, form element will be hidden in the form and value will not be written to dto. */
    disabled?: boolean;
    userChangeable?: boolean; // TODO not implemented for all elements.
    validators?: RuleDecl;
    conditionalInfoKey?: string;
};

export const formElementDefaultConfig = {
    hidden: false,
    disabled: false,
    userChangeable: true,
    validators: {},
    conditionalInfoKey: '',
};

export class FormElement {
    public hidden: boolean;
    public disabled: boolean;
    public userChangeable: boolean;
    public validators: RuleDecl;
    public conditionalInfoKey: string;

    public constructor(config?: FormElementConfig) {
        const resolvedConfig = _defaults(
            config || ({} as FormElementConfig),
            formElementDefaultConfig,
        );
        this.hidden = resolvedConfig.hidden;
        this.disabled = resolvedConfig.disabled;
        this.userChangeable = resolvedConfig.userChangeable;
        this.validators = resolvedConfig.validators;
        this.conditionalInfoKey = resolvedConfig.conditionalInfoKey;
    }
}
