import { JSONSchema7Definition } from 'json-schema';

interface FormDtoAttributeData {
    jsonSchema?: JSONSchema7Definition;
}

const dtoAttributes: Record<string, FormDtoAttributeData> = {};

/**
 * Adds attribute to dto, saving it to the database.
 */
export function formDtoAttribute(data?: FormDtoAttributeData) {
    return (target: unknown, propertyKey: string): void => {
        dtoAttributes[propertyKey] = data || {};
    };
}

export function isFormDtoAttribute(propertyKey: string): boolean {
    return propertyKey in dtoAttributes;
}

export function getFormDtoAttributeData(
    propertyKey: string,
): FormDtoAttributeData | null {
    return dtoAttributes[propertyKey] || null;
}
