import { PropertyOwnerFormStepContent } from '@/api/interfaces/content/form/steps/propertyOwnerFormStepContent';

export const propertyOwnerContent: PropertyOwnerFormStepContent = {
    stepHeading: 'Grundstückseigentümer',
    buttonNext: {
        text: 'Weiter',
    },
    propertyOwnerAgreement: {
        label:
            'Ich bin nicht Haus- und Grundstückseigentümer. Habe diese Beauftragung jedoch mit ihm abgestimmt und seine Zustimmung liegt mir vor.',
    },
    salutation: {
        label: 'Anrede',
        summaryLabel: 'Anrede angegeben als',
        options: {
            mr: {
                label: 'Herr',
            },
            mrs: {
                label: 'Frau',
            },
            company: {
                label: 'Firma',
            },
        },
    },
    companyName: {
        label: 'Firmenname',
        placeholder: 'Firmenname',
    },
    title: {
        label: 'Titel',
        summaryLabel: 'Titel angegeben als',
        options: {
            untitled: {
                label: 'Kein Titel',
            },
            dr: {
                label: 'Dr.',
            },
            prof: {
                label: 'Prof.',
            },
            profDr: {
                label: 'Prof. Dr.',
            },
        },
    },
    ownerName: {
        label: 'Vor- und Nachname',
        firstName: {
            placeholder: 'Vorname',
        },
        lastName: {
            placeholder: 'Nachname',
        },
    },
    contactPersonName: {
        label: 'Vor- und Nachname des Ansprechpartners',
        firstName: {
            placeholder: 'Vorname',
        },
        lastName: {
            placeholder: 'Nachname',
        },
    },
    birthday: {
        label: 'Geburtsdatum',
        info: 'Bitte geben Sie das Geburtsdatum im Format TT.MM.JJJJ ein.',
        placeholder: 'Geburtstag',
    },
    country: {
        label: 'Land',
        summaryLabel: 'Land angegeben als',
        options: {
            deu: {
                label: 'Deutschland',
            },
            aut: {
                label: 'Österreich',
            },
            dnk: {
                label: 'Dänemark',
            },
            fra: {
                label: 'Frankreich',
            },
            nld: {
                label: 'Niederlande',
            },
            pol: {
                label: 'Polen',
            },
            che: {
                label: 'Schweiz',
            },
        },
    },
    zipAndCity: {
        label: 'PLZ und Ort',
        zip: {
            placeholder: 'PLZ',
        },
        city: {
            placeholder: 'Ort',
        },
    },
    streetAndHousenumber: {
        label: 'Stra&szlig;e und Hausnummer',
        street: {
            placeholder: 'Straße',
        },
        housenumber: {
            placeholder: 'Nr.',
        },
    },
    email: {
        label: 'E-Mail',
        placeholder: 'E-Mail',
    },
    phoneNumber1: {
        label: 'Telefon- oder Mobilfunknummer I',
        placeholder: 'Telefon- oder Mobilfunknummer I',
    },
    phoneNumber2: {
        label: 'Telefon- oder Mobilfunknummer II',
        placeholder: 'Telefon- oder Mobilfunknummer II',
    },
    addRemovePropertyOwner: {
        addLabel: 'Weiteren Grundst&uuml;ckseigent&uuml;mer hinzuf&uuml;gen',
        removeLabel: 'Grundst&uuml;ckseigent&uuml;mer entfernen',
    },
};
