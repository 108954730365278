import { BillingFormStepContent } from '@/api/interfaces/content/form/steps/billingFormStepContent';

export const billingContent: BillingFormStepContent = {
    stepHeading: 'Abrechnung',
    buttonNext: {
        text: 'Weiter',
    },
    paymentRequirements: {
        text:
            'EWE NETZ wird die Vergütung der eingespeisten elektrischen Energie ausschließlich gemäß den Vorgaben des EEG oder KWKG und durch Erteilung von Gutschriften vornehmen. EWE NETZ zahlt monatliche Abschläge. Der Messstellenbetreiber führt für die Dauer der Messmethodik die jährlich erfassten Messergebnisse der Zählungen durch. EWE NETZ soll die jährliche Abrechnung auf der Basis dieser Daten vornehmen. Es ist Sache des Anlagenbetreibers, diese EWE NETZ jeweils zu übermitteln oder übermitteln zu lassen, falls EWE NETZ nicht Messstellenbetreiber ist. Grundlage für die Ermittlung der Vergütung ist das Messergebnis der Übergabemesseinrichtung.',
    },
    noEegRemuneration: {
        label:
            'Der Anlagenbetreiber verzichtet auf die EEG-Verg&uuml;tung gem&auml;&szlig; &sect;7&nbsp;Abs.&nbsp;2&nbsp;EEG',
    },
    reasonNoEegRemuneration: {
        label: 'Grund des Verzichts',
        options: {
            economicDisadvantages: {
                label: 'EEG-Vergütung führt zu wirtschaftlichen Nachteilen',
            },
            other: {
                label: 'Anderer Grund',
            },
        },
    },
    explanationNoEegRemuneration: {
        label: 'Begr&uuml;ndung',
    },
    noEegRemunerationHint: {
        text:
            '<p>Soweit der erzeugte Strom in das Netz des Netzbetreibers eingespeist wird, kann unter den Voraussetzungen des EEG ein Anspruch auf F&ouml;rderung gegen&uuml;ber dem Netzbetreiber entstehen. Der Anlagenbetreiber verzichtet gegen&uuml;ber dem Netzbetreiber aus den vorgenannten Gr&uuml;nden auf den Anspruch auf F&ouml;rderung. Der Verzicht bezieht alle Anspr&uuml;che auf F&ouml;rderung des eingespeisten Stroms nach dem EEG.</p><p>Die Erkl&auml;rung bezieht sich auf s&auml;mtliche Anspr&uuml;che ab der Inbetriebnahme der Erzeugungsanlage.</p>',
    },
    entrepreneurHint: {
        text:
            'Betreiber von Anlagen zur Stromgewinnung i.S. des EEG und KWKG sind nach den Vorschriften des Umsatzsteuergesetzes grundsätzlich Unternehmer, wenn sie den erzeugten Strom ganz oder teilweise und nicht nur gelegentlich in das allgemeine Stromnetz einspeisen (Abschnitt 2.5 Umsatzsteueranwendungserlass).',
    },
    taxNumberWillFollow: {
        label: 'Die Steuernummer wird nachgereicht',
    },
    taxNumber: {
        label:
            'Steuernummer (inkl. Länderschlüssel) - Beispiel: 23/XX/XXX/XXXXX',
        placeholder: 'Steuernummer',
        info:
            'Sollten Sie bereits Betreiber einer weiteren Erzeugungsanlage sein, geben Sie hier bitte die vorhandene Steuernummer an.',
    },
    tax: {
        label: "<strong style='font-size: 20px;'>Steuer</strong>",
        options: {
            noSalesTax: {
                label:
                    '<strong>Nicht Umsatzsteuerpflichtig</strong><br /><br />Zu dem ermittelten Entgelt f&uuml;r die Einspeisung elektrischer Energie in das Netz von EWE NETZ <strong>wird keine Umsatzsteuer</strong> von EWE NETZ <strong>verg&uuml;tet</strong>.<br /><br /><strong>Begr&uuml;ndung:</strong> Der Anlagenbetreiber ist Kleinunternehmer im Sinne des &sect; 19 UStG bzw.&nbsp; unterliegt nicht dem Umsatzsteuergesetz. Die von EWE NETZ zu erstellenden Gutschriften weisen demnach keine Umsatzsteuer aus.',
            },
            salesTax: {
                label:
                    '<strong>Umsatzsteuerpflichtig</strong><br /><br />Zu dem ermittelten Entgelt f&uuml;r die Einspeisung elektrischer Energie in das Netz von EWE NETZ <strong>wird die Umsatzsteuer</strong> von EWE NETZ in der jeweiligen gesetzlich festgelegten H&ouml;he zus&auml;tzlich <strong>verg&uuml;tet</strong>.<br /><br /><strong>Begr&uuml;ndung:</strong> Der Anlagenbetreiber tritt als regelbesteuernder Unternehmer auf bzw. verzichtet auf die Anwendung des &sect; 19 Abs. 1 UStG und optiert freiwillig zur Regelbesteuerung. Der Anlagenbetreiber f&uuml;hrt die Umsatzsteuer an das Finanzamt ab.<br /><br />Falls die Anlagen zur Stromgewinnung Teil eines land- und forstwirtschaftlichen Betriebes ist und einen verringerten Steuersatz gem. &sect; 24 Abs. 3 UStG unterliegt, informiert der Anlagenbetreiber EWE NETZ gesondert.',
            },
        },
    },
    plantOperatorObligationTaxChange: {
        text:
            'Der Anlagenbetreiber ist verpflichtet, eine &Auml;nderung seiner steuerlichen Verh&auml;ltnisse (z.B. Wechsel von Regelbesteuerung zur Kleinunternehmerregelung, &Auml;nderung der Steuernummer) dem Netzbetreiber unverz&uuml;glich mitzuteilen.<br /><br />Eine nach den Vorschriften des Umsatzsteuergesetzes unberechtigt ausgewiesene und vom Netzbetreiber ausbezahlte Umsatzsteuer erstattet der Anlagenbetreiber.',
    },
    directDebitMandate: {
        text:
            "<strong style='font-size: 20px;'>SEPA-Lastschriftenmandat</strong><br><br>Hiermit ermächtigt der Kontoinhaber die EWE NETZ GmbH bis auf Widerruf damit, fällige Beträge im Zusammenhang mit der Stromeinspeisung bzw. deren Abwicklung vom unten angegebenen Konto wiederkehrend mittels Lastschrift einzuziehen. Zugleich wird das Kreditinstitut angewiesen, die von EWE NETZ auf das Konto gezogenen Lastschriften einzulösen. Den Nachweis des SEPA-Lastschriftmandates gegenüber der Bank erbringt EWE NETZ.<br><br><strong>Gläubiger ID-Nr. EWE NETZ: DE13ZZZ00000023597</strong><br><br><strong>Hinweis:</strong> Innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, kann die Erstattung des Lastschriftbetrages vom Kontoinhaber verlangt werden. Es gelten dabei die mit dem Kreditinstitut vereinbarten Bedingungen. Änderungen der Bankverbindung sowie sonstige für dieses SEPA-Lastschriftmandat relevante Änderungen wird der Kontoinhaber EWE NETZ unverzüglich in Schriftform mitteilen.",
    },
    iban: {
        label: 'IBAN',
        placeholder: 'IBAN',
    },
    nameAndDomicileCreditInstitution: {
        label: 'Name und Sitz des Kreditinstituts',
        placeholder: 'Name und Sitz des Kreditinstituts',
    },
    date: {
        label: 'Datum',
        info: 'Bitte geben Sie das Datum im Format TT.MM.JJJJ ein.',
        placeholder: 'Datum',
    },
    accountOwnerName: {
        label: 'Vorname und Name Kontoinhaber',
        placeholder: 'Vorname und Name Kontoinhaber',
    },
};
