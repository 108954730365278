import type { ToolsRepositoryInterface } from '@/api/interfaces/tools/toolsRepository';
import { staticImplements } from '@/decorators/staticImplements';
import { httpClient } from '../httpClient/httpClient';
import { BakeApplicationSummariesApplicationsResponse } from '@/api/interfaces/tools/bakeApplicationSummariesApplicationsResponse';

@staticImplements<ToolsRepositoryInterface>()
export class ToolsRepository {
    static bakeApplicationSummariesFetchApplications(): Promise<
        BakeApplicationSummariesApplicationsResponse
    > {
        return httpClient.get<BakeApplicationSummariesApplicationsResponse>(
            '/api/dea/v1/tools/bakeApplicationSummaries',
        );
    }

    static bakeApplicationSummariesProcessApplications(
        limit: number,
    ): Promise<BakeApplicationSummariesApplicationsResponse> {
        return httpClient.get<BakeApplicationSummariesApplicationsResponse>(
            `/api/dea/v1/tools/bakeApplicationSummaries/${limit}`,
        );
    }
    static bakeApplicationSummariesDeleteApplications(): Promise<
        BakeApplicationSummariesApplicationsResponse
    > {
        return httpClient.delete<BakeApplicationSummariesApplicationsResponse>(
            '/api/dea/v1/tools/bakeApplicationSummaries',
        );
    }
}
