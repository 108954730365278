import { MockService } from '../mockService';
import type { DocumentRepositoryInterface } from '@/api/interfaces/document/documentRepositoryInterface';
import { staticImplements } from '@/decorators/staticImplements';
import { DocumentDownloadResponse } from '@/api/interfaces/document/documentDownloadResponse';
import { DocumentId } from '@/api/interfaces/document/documentId';

@staticImplements<DocumentRepositoryInterface>()
export class DocumentRepository {
    static async downloadDocument(
        documentId: DocumentId,
        applicationId: string,
    ): Promise<DocumentDownloadResponse> {
        const mockService = new MockService<DocumentDownloadResponse>();

        const response: DocumentDownloadResponse = {
            fileName: `Dummy file ${documentId}`,
            bytes: btoa(
                `File-ID: ${documentId}, Application-ID: ${applicationId}`,
            ),
        };

        return mockService.runServiceWithSuccess(response, 1000);
    }
}
