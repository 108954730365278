import { interpolate, InterpolationPlaceholders } from './interpolationService';
import { contentServices } from './contentService';
import { get as _get } from 'lodash';

class MessageService {
    private fatalErrorCode = 'fatalError';

    private getMessageFromContent(
        identifier: string,
        placeholders?: InterpolationPlaceholders,
        fallbackIdentifier?: string,
    ): string {
        if (!contentServices.app.content) {
            return identifier;
        }

        let message = _get(
            contentServices.app.content.messagesContent,
            identifier,
        );

        if (!message && fallbackIdentifier) {
            message = _get(
                contentServices.app.content.messagesContent,
                fallbackIdentifier,
                identifier,
            );
        }

        if (typeof message === 'string' && placeholders) {
            return interpolate(message, placeholders);
        }

        return typeof message === 'string' ? message : identifier;
    }

    public getInfoMessage(
        identifier: string,
        placeholders?: InterpolationPlaceholders,
    ): string {
        return this.getMessageFromContent(identifier, placeholders);
    }

    public getErrorMessage(
        identifier: string,
        placeholders?: InterpolationPlaceholders,
    ): string {
        return this.getMessageFromContent(
            identifier,
            placeholders,
            this.fatalErrorCode,
        );
    }
}

export const messageService = new MessageService();
