import { Application } from '@/api/interfaces/application/application';
import type { ApplicationRepositoryInterface } from '@/api/interfaces/application/applicationRepository';
import { staticImplements } from '@/decorators/staticImplements';
import { httpClient } from '../httpClient/httpClient';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { convertFormConfigToFormDto } from '@/services/form/dto';
import { FormDto } from '@/services/form/dto';
import { ServiceSuccess } from '@/api/interfaces/serviceSuccess';

@staticImplements<ApplicationRepositoryInterface>()
export class ApplicationRepository {
    static fetchApplications(): Promise<Application[]> {
        return httpClient.get<Application[]>('/api/dea/v1/application');
    }

    static getApplicationData(applicationId: string): Promise<FormDto> {
        return httpClient.get<FormDto>(
            `/api/dea/v1/application/${applicationId}/data`,
        );
    }

    static copyApplication(applicationId: string): Promise<string> {
        return httpClient.get<string>(
            `/api/dea/v1/application/${applicationId}/copy`,
        );
    }

    static deleteApplication(applicationId: string): Promise<ServiceSuccess> {
        return httpClient.delete<ServiceSuccess>(
            `/api/dea/v1/application/${applicationId}`,
        );
    }

    static saveApplication(model: ApplicationForm): Promise<ServiceSuccess> {
        return httpClient.post<ServiceSuccess>(
            `/api/dea/v1/application/${model.applicationId}`,
            {
                data: { ...convertFormConfigToFormDto(model) },
            },
        );
    }

    static sendApplication(model: ApplicationForm): Promise<ServiceSuccess> {
        return httpClient.post<ServiceSuccess>(
            `/api/dea/v1/application/${model.applicationId}/send`,
            {
                data: { ...convertFormConfigToFormDto(model) },
            },
        );
    }
}
