export function concat(strings: string[], excludeNull = true): string {
    if (!strings) {
        return '';
    }

    if (excludeNull) {
        return strings.filter((x) => x).join('');
    }

    return strings.join('');
}
