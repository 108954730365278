import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { authService, Role } from '@/services/authService';

Vue.use(VueRouter);

const allowAnonymous = () => ({
    allowAnonymous: true,
});

const isStatic = () => ({
    staticRoute: true,
});

const requireRoles = (...roles: Role[]) => ({
    requiredRoles: roles,
});

const routes = [
    {
        path: '/',
        name: 'Application Overview',
        component: () =>
            import(
                /* webpackChunkName: "ApplicationOverview" */ '../components/pages/applicationOverview/ApplicationOverview.vue'
            ),
    },
    {
        path: '/antrag/:applicationId?',
        name: 'Application',
        component: () =>
            import(
                /* webpackChunkName: "Application" */ '../components/pages/applicationForm/ApplicationForm.vue'
            ),
    },
    {
        path: '/danke',
        name: 'Thank You',
        component: () =>
            import(
                /* webpackChunkName: "ThankYou" */ '../components/pages/thankYou/ThankYou.vue'
            ),
    },
    {
        path: '/admin',
        name: 'Admin',
        meta: { ...requireRoles('DEA Admin') },
        component: () =>
            import(
                /* webpackChunkName: "Admin" */ '../components/pages/admin/Admin.vue'
            ),
    },
    {
        path: '/tools',
        name: 'Tools',
        meta: { ...requireRoles('DEA Admin') },
        component: () =>
            import(
                /* webpackChunkName: "Tools" */ '../components/pages/tools/Tools.vue'
            ),
    },
    {
        path: '/tools/bake-application-summaries',
        name: 'Tool Bake Application Summaries',
        meta: { ...requireRoles('DEA Admin') },
        component: () =>
            import(
                /* webpackChunkName: "ToolBakeApplicationSummaries" */ '../components/pages/tools/BakeApplicationSummaries.vue'
            ),
    },
    {
        path: '/dummy/kitchensink',
        name: 'Dummy Kitchen Sink',
        meta: { ...requireRoles('DEA Dummy') },
        component: () =>
            import(
                /* webpackChunkName: "DummyKitchenSink" */ '../components/pages/dummy/kitchenSink/KitchenSink.vue'
            ),
    },
    {
        path: '/dummy/application-form-schema',
        name: 'Dummy Application Form Schema',
        meta: { ...requireRoles('DEA Dummy') },
        component: () =>
            import(
                /* webpackChunkName: "DummyApplicationFormSchema" */ '../components/pages/dummy/applicationFormSchema/ApplicationFormSchema.vue'
            ),
    },
    {
        path: '/logout',
        name: 'Logout',
        async beforeEnter() {
            await authService.logout();
        },
    },
    {
        path: '/login-callback',
        name: 'Login Callback',
        meta: { ...allowAnonymous() },
        async beforeEnter() {
            await authService.handleLoginCallback();
        },
    },
    {
        path: '/fehler',
        name: 'Error',
        meta: { ...allowAnonymous(), ...isStatic() },
        components: {
            static: () =>
                import(
                    /* webpackChunkName: "StaticError" */ '../components/pages/staticError/StaticError.vue'
                ),
        },
    },
    {
        path: '/wartung',
        name: 'Maintenance',
        meta: { ...allowAnonymous(), ...isStatic() },
        components: {
            static: () =>
                import(
                    /* webpackChunkName: "StaticError" */ '../components/pages/staticError/StaticError.vue'
                ),
        },
    },
    {
        // route all unknown paths to the home page
        path: '*',
        redirect: '/',
    },
] as RouteConfig[];

const router = new VueRouter({
    mode: 'history',
    routes,
});

// intercepter, which checks authentication and authorization before querying a route
router.beforeEach(async (to, from, next) => {
    const meta = to.meta || {};

    // restore authentication (by OIDC user in session storage)
    await authService.restore();

    // grant access, if the route requires no authentication
    if (meta.allowAnonymous) {
        next();
        return;
    }

    // initiate authentication process
    await authService.authenticate();

    // cancel, if the user is not authenticated (user will be forwarded to cidaas login)
    if (!authService.isAuthenticated) {
        return;
    }

    // show error that the user is not authorized to request the specific page
    if (!authService.hasRoles('DEA User')) {
        next({ name: 'Error' });
        return;
    }

    // determine required roles
    const requiredRoles = Array.isArray(meta.requiredRoles)
        ? (meta.requiredRoles as Role[])
        : [];

    // forward to home page and show error, if the user is not authorized
    if (!authService.hasRoles(...requiredRoles)) {
        next({ name: 'Application Overview', query: { code: 'unauthorized' } });
        return;
    }

    // grant access
    next();
});

export default router;
