import { locationContent } from './application/location';
import { generationTypeContent } from './application/generationType';
import { powerConnectionContent } from './application/powerConnection';
import { plantOperatorContent } from './application/plantOperator';
import { plantBuilderContent } from './application/plantBuilder';
import { plantDataContent } from './application/plantData';
import { plantPerformanceContent } from './application/plantPerformance';
import { propertyOwnerContent } from './application/propertyOwner';
import { billingContent } from './application/billing';
import { ApplicationFormContent } from '@/api/interfaces/content/form/applicationFormContent';
import { measuringConceptsContent } from './application/measuringConcepts';
import { documentsContent } from './application/documents';
import { checkAndSendContent } from './application/checkAndSend';
import { controllableConsumptionDevicesContent } from './application/controllableConsumptionDevices';

export const applicationFormContent: ApplicationFormContent = {
    stageContent: {
        icon: 'formular',
        heading: 'Melden Sie Ihre Erzeugungsanlage',
        content:
            'Hier können Sie Ihre Erzeugungsanlage melden. Dazu einfach die notwendigen Felder im Formular ausfüllen und absenden.',
    },
    common: {
        formStep: {
            buttonEdit: {
                text: 'Bearbeiten',
            },
            buttonCancel: {
                text: 'Abbrechen',
            },
            formStepIconRemainingWithData: {
                text:
                    'Durch die vorhergehende Änderung ist eine erneute Validierung dieses Schritts notwendig.',
            },
            requiredFieldsText: {
                text: '* Pflichtfelder',
            },
        },
    },
    editConfirmationLayer: {
        confirmationText:
            'Sind Sie sicher? Mit Speichern der Änderung werden sämtliche nachfolgende Eingaben gelöscht.',
        buttonAccept: {
            text: 'Ja, ich möchte speichern',
        },
        buttonCancel: {
            text: 'Abbrechen',
        },
    },
    locationContent: locationContent,
    generationTypeContent: generationTypeContent,
    powerConnectionContent: powerConnectionContent,
    plantOperatorContent: plantOperatorContent,
    plantBuilderContent: plantBuilderContent,
    plantDataContent: plantDataContent,
    plantPerformanceContent: plantPerformanceContent,
    propertyOwnerContent: propertyOwnerContent,
    billingContent: billingContent,
    documentsContent: documentsContent,
    measuringConceptsContent: measuringConceptsContent,
    controllableConsumptionDevicesContent: controllableConsumptionDevicesContent,
    checkAndSendContent: checkAndSendContent,
};
