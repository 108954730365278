import { GeneratorFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/generatorContent';

export const generatorContent: GeneratorFormGroupContent = {
    generatorHeadline: {
        text: 'Generatoren',
    },
    generatorManufacturerAndModuleType: {
        label: 'Hersteller und Typ',
        manufacturer: {
            placeholder: 'Eco Power',
        },
        moduleType: {
            placeholder: 'Power Unit XRGI 6',
        },
    },
    generatorQuantityAndGeneratorType: {
        label: 'Anzahl und Generatorentyp',
        generatorType: {
            placeholder: 'Bitte wählen',
            info:
                'Eine Erzeugungseinheit Typ 1 beinhaltet ausschließlich einen Synchrongenerator, der einen direkt oder nur über einen Maschinentransformator mit dem Netz gekoppelt ist.\nEine Erzeugungseinheit Typ 2 ist jede Einheit, welche nicht die Kriterien einer Typ 1 Einheit erfüllt.',
            options: {
                type1: {
                    label: 'Typ 1',
                },
                type2: {
                    label: 'Typ 2',
                },
            },
        },
    },
    generatorContributionSymmetricalShortCircuitCurrent: {
        label: 'Beitrag zum Anfangskurzschlusswechselstrom I<sub>k</sub>',
        placeholder: '50',
        unit: 'kA',
        info:
            'F&uuml;r eine Absch&auml;tzung kann der Anteil aus den Erzeugungseinheiten ohne Wechselrichter (I<sub>k</sub>) und der Effektivwert des Quellstroms aus den Erzeugungseinheiten (I<sub>skPF</sub>) addiert werden.',
    },
    generatorVoltageSymmetricalShortCircuitCurrent: {
        label:
            'Bei welcher Spannung ist der Beitrag zum Anfangskurzschlusswechselstrom',
        placeholder: '400',
        unit: 'V',
    },
    generatorContributionSustainedShortCircuitCurrent: {
        label: 'Beitrag zum Dauerkurzschlussstrom I<sub>k</sub>',
        placeholder: '20',
        unit: 'kA',
    },
    generatorVoltageSustainedShortCircuitCurrent: {
        label:
            'Bei welcher Spannung ist der Beitrag zum Dauerkurzschlusswechselstrom',
        placeholder: '400',
        unit: 'V',
    },
    generatorActivePowerAndApparentPower: {
        label:
            'Bemessungswirkleistung P<sub>rE</sub> und Bemessungsscheinleistung S<sub>rE</sub>',
        activePower: {
            placeholder: '10',
            unit: 'kW',
        },
        apparentPower: {
            placeholder: '10',
            unit: 'kVA',
        },
    },
    generatorTotalActivePower: {
        label: 'Summe Bemessungswirkleistung P<sub>rE</sub>',
        unit: '{{ value }} kW',
    },
    generatorTotalApparentPower: {
        label: 'Summe Bemessungsscheinleistung S<sub>rE</sub>',
        unit: '{{ value }} kVA',
    },
    generatorTotalActivePowerIsGreaterThanTotalApparentPowerWarning: {
        text:
            'Die Summe der Wirkleistung kann nie größer sein als die Summe der Scheinleistung.',
    },
    generatorAddRemoveModule: {
        addLabel: 'Generator hinzuf&uuml;gen',
        removeLabel: 'Generator entfernen',
    },
    generatorOverallActivePower: {
        label: 'Gesamtergebnis aus Summe Wirkleistung',
        unit: '{{ value }} kW',
    },
    generatorOverallApparentPower: {
        label: 'Gesamtergebnis aus Summe Scheinleistung',
        unit: '{{ value }} kVA',
    },
};
