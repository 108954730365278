



















import Vue, { PropType } from 'vue';
import { ButtonCharacter } from '@/enums/buttonCharacter';
import { ButtonTheme } from '@/enums/buttonTheme';

export default Vue.extend({
    name: 'AppButton',
    props: {
        label: {
            type: String,
            default: null,
        },
        character: {
            type: String as PropType<ButtonCharacter>,
            default: ButtonCharacter.raquo,
        },
        characterIsLeft: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String as PropType<ButtonTheme>,
            default: ButtonTheme.buttonPrimary,
        },
    },
    computed: {
        includeCharacter(): boolean {
            return this.character !== ButtonCharacter.none;
        },
        includeCharacterLeft(): boolean {
            return this.includeCharacter && this.characterIsLeft;
        },
        includeCharacterRight(): boolean {
            return this.includeCharacter && !this.characterIsLeft;
        },
    },
});
