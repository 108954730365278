import { ApplicationOverviewContent } from '@/api/interfaces/content/app/applicationOverviewContent';

export const applicationOverviewContent: ApplicationOverviewContent = {
    stageContent: {
        icon: 'formular',
        heading: 'Antrags&uuml;bersicht',
        content:
            'Hier finden Sie die Liste Ihrer Antr&auml;ge f&uuml;r Eigenerzeugungsanlagen an unser Netz.',
    },
    introContent: {
        content:
            '<p>Die bereits gesendeten Antr&auml;ge k&ouml;nnen Sie aufrufen, bis wir dem zuk&uuml;nftigen Anlagenbetreiber das Angebot zusenden. Noch nicht gesendete Antr&auml;ge k&ouml;nnen Sie vervollst&auml;ndigen.</p><p>Haben Sie Fragen zur Antragsbearbeitung bzw. zu Ihren gesendeten Antr&auml;gen, dann rufen Sie uns an.</p><p>Bitte halten Sie f&uuml;r einen neuen Antrag einen Lageplan mit Flurst&uuml;cknummer(n), Grundst&uuml;cksgrenzen, Aufstellungsort der Erzeugungsanlage (mit Kennzeichnung der Neuanlage und gegebenenfalls der Bestandsanlage/n) in elektronischer Form (als jpg oder pdf) bereit.</p>',
        heading: 'Wie arbeiten Sie mit Ihren Anträgen?',
    },
    contactBoxContent: {
        heading: 'Sprechen wir zusammen.',
        content: 'Für den kostenlosen Kontakt mit uns wählen Sie einfach:',
        telephoneLinks: [
            {
                text: 'T 0800 3936389',
            },
            {
                text: 'F 0441 4808 1195',
            },
        ],
        url: '#',
        text: 'zum Kontaktformular',
    },
    applicationOverviewGridContent: {
        columns: {
            address: {
                name: 'Adresse',
            },
            applicationId: {
                name: 'Antrags-ID',
            },
            applicationNumber: {
                name: 'Antrags&shy;nummer',
            },
            completed: {
                name: 'Antrag vollständig',
            },
            created: {
                name: 'Anlagedatum',
            },
            name: {
                name: 'Name',
            },
            status: {
                name: 'Status',
            },
            submitted: {
                name: 'Versand&shy;datum',
            },
            technology: {
                name: 'Technologie',
            },
        },
        newApplicationText: 'Neuer Antrag',
        noApplicationsText:
            "Keine Antr&auml;ge wurden gefunden. Bitte 'Neuer Antrag' klicken, um einen neuen Antrag zu starten",
        copyButtonText: 'Kopieren',
        deleteButtonText: 'Löschen',
        editButtonText: 'Bearbeiten',
        downloadButtonText: 'Herunterladen',
    },
    copyApplicationConfirmationLayer: {
        confirmationText:
            'Möchten Sie die Antragsdaten in einen neuen Antrag kopieren?',
        buttonAccept: {
            text: 'Ja, ich möchte kopieren',
        },
        buttonCancel: {
            text: 'Abbrechen',
        },
    },
    deleteApplicationConfirmationLayer: {
        confirmationText:
            'Sind Sie sicher? Der Antrag wird unwiderruflich gelöscht.',
        buttonAccept: {
            text: 'Ja, ich möchte löschen',
        },
        buttonCancel: {
            text: 'Abbrechen',
        },
    },
};
