import { InfeedManagementFormGroupContent } from '@/api/interfaces/content/form/steps/plantData/infeedManagementContent';

export const infeedManagementContent: InfeedManagementFormGroupContent = {
    imHeadline: {
        text: 'Einspeisemanagement',
        conditionalInfo: {
            pvInfo: {
                info:
                    'Solaranlagen sind gemäß EEG mit technischen Einrichtungen auszustatten, die zur ferngesteuerten Reduzierung der Einspeiseleistung bei Netzüberlastung dienen. Im Falle einer Netzüberlastung darf EWE NETZ als Netzbetreiber auf die genannten Einrichtungen zugreifen, um weiterhin einen sicheren Netzbetrieb zu gewährleisten.Für die Versendung des Signals zur Leistungsreduzierung wird von EWE NETZ die Tonfrequenzrundsteuer-Technik eingesetzt. Detaillierte Informationen zu den Technischen Anforderungen zur Umsetzung des Einspeisemanagements im Verteilnetz Strom der EWE NETZ GmbH sowie bebilderte Montagebeispiele finden Sie auf unserer Internetseite unter <a href="http://www.ewe-netz.de">www.ewe-netz.de</a>.',
            },
            otherInfo: {
                info:
                    'Erzeugungsanlagen sind mit technischen Einrichtungen auszustatten, die zur ferngesteuerten Reduzierung der Einspeiseleistung bei Netzüberlastung dienen. Im Falle einer Netzüberlastung darf EWE NETZ als Netzbetreiber auf die genannten Einrichtungen zugreifen, um weiterhin einen sicheren Netzbetrieb zu gewährleisten.Für die Versendung des Signals zur Leistungsreduzierung wird von EWE NETZ die Tonfrequenzrundsteuer-Technik eingesetzt. Detaillierte Informationen zu den Technischen Anforderungen zur Umsetzung des Einspeisemanagements im Verteilnetz Strom der EWE NETZ GmbH sowie bebilderte Montagebeispiele finden Sie auf unserer Internetseite unter <a href="http://www.ewe-netz.de">www.ewe-netz.de</a>.',
            },
        },
    },
    imPowerReductionType: {
        label: 'Art der Leistungsreduzierung',
        options: {
            noPowerReduction: {
                label: 'Keine Leistungsreduzierung',
            },
            infeedActivePowerPvAndDcCoupling: {
                label:
                    'Begrenzung der maximalen Wirkleistungseinspeisung auf 70% (Nur für gekoppelte DC Speicher und Ladung aus einer Solaranlage mit einer installierten Leistung von bis zu 25 kW möglich)',
            },
            audioFrequencyControlReceiverInternal: {
                label:
                    'Leistungsreduzierung durch einen Tonfrequenzsteuerempfänger (Auftrag EWE NETZ)',
            },
            audioFrequencyControlReceiverExternal: {
                label:
                    'Leistungsreduzierung durch einen Tonfrequenzsteuerempfänger (externer Auftrag)',
            },
            remoteControlCenter: {
                label: 'Leistungsreduzierung durch eine Fernwirkanlage',
            },
            audioFrequencyControlReceiverPv: {
                label:
                    'Leistungsreduzierung durch einen Tonfrequenzsteuerempfänger ist mit der Antragstellung der Solaranlage beauftragt',
            },
        },
        conditionalInfo: {
            isStorageWithMiddleRangeDischargePower: {
                info:
                    'Konventionelle Speicher sind mit technischen Einrichtungen auszustatten, die zur ferngesteuerten Reduzierung der Einspeiseleistung bei Netzüberlastung dienen. Im Falle einer Netzüberlastung darf EWE NETZ als Netzbetreiber auf die genannten Einrichtungen zugreifen, um weiterhin einen sicheren Netzbetrieb zu gewährleisten.Für die Versendung des Signals zur Leistungsreduzierung wird von EWE NETZ die Tonfrequenzrundsteuer-Technik eingesetzt. Detaillierte Informationen zu den Technischen Anforderungen zur Umsetzung des Einspeisemanagements im Verteilnetz Strom der EWE NETZ GmbH sowie bebilderte Montagebeispiele finden Sie auf unserer Internetseite unter <a href="http://www.ewe-netz.de">www.ewe-netz.de</a>.',
            },
            isStorageWithHighRangeDischargePower: {
                info:
                    'Speicher mit einer installierten Leistung > 100 kW sind mit technischen Einrichtungen auszustatten, die zur ferngesteuerten Reduzierung der Einspeiseleistung bei Netzüberlastung dienen und zur Abrufung der jeweiliegen Ist - Einspeisung dienen. Im Falle einer Netzüberlastung darf EWE NETZ als Netzbetreiber auf die genannten Einrichtungen zugreifen, um weiterhin einen sicheren Netzbetrieb zu gewährleisten. Für die Versendung des Signals zur Leistungsreduzierung wird von EWE NETZ die Fernwirktechnik gefordert. Detaillierte Informationen zu den Technischen Anforderungen zur Umsetzung des Einspeisemanagements im Verteilnetz Strom der EWE NETZ GmbH finden Sie auf unserer Internetseite unter <a href="http://www.ewe-netz.de">www.ewe-netz.de</a>.',
            },
            isStorageWithLowRangeDischargePower: {
                info:
                    'DC gekoppelte Speicher und Ladung aus einer Solaranlage sind gemäß EEG mit technischen Einrichtungen auszustatten, die zur ferngesteuerten Reduzierung der Einspeiseleistung bei Netzüberlastung dienen. Im Falle einer Netzüberlastung darf EWE NETZ als Netzbetreiber auf die genannten Einrichtungen zugreifen, um weiterhin einen sicheren Netzbetrieb zu gewährleisten.Für die Versendung des Signals zur Leistungsreduzierung wird von EWE NETZ die Tonfrequenzrundsteuer-Technik eingesetzt. Detaillierte Informationen zu den Technischen Anforderungen zur Umsetzung des Einspeisemanagements im Verteilnetz Strom der EWE NETZ GmbH sowie bebilderte Montagebeispiele finden Sie auf unserer Internetseite unter <a href="http://www.ewe-netz.de">www.ewe-netz.de</a>.',
            },
        },
    },
    imAudioFrequencyControlReceiverContract: {
        text:
            'Auftrag über die Bereitstellung und Montage eines Tonfrequenzrundsteuerempfängers (TRE) zur Erfüllung der technischen Vorgaben',
        info:
            'Bitte stellen Sie sicher, dass bei der Installation des TREs die Steuerleitungen von Ihrer Anlage, sowie eine 230V-Versorgungsleitung bis zum gewünschten Montageort verlegt und betriebsbereit sind. Wir empfehlen Ihnen eine Montage des TREs in unmittelbarer Nähe zum zentralen Zählerplatz. <br><br>Die Weiterleitung und Verarbeitung der von dem TRE zur Verfügung gestellten Ausgangssignale ist nicht Bestandteil dieses Angebotes. Damit Ihre Erzeugungsanlage entsprechend der empfangenen Signale geregelt werden kann, ist es erforderlich, den TRE in die Steuerung Ihrer Erzeugungsanlage einzubinden. Dies kann z. B. durch Ihren Elektroinstallateur erfolgen und ist von Ihnen separat zu veranlassen.',
    },
    imProvisionThrough: {
        label: 'Bereitstellung durch',
    },
    imTechnicalSpecificationsNote: {
        label: 'Hinweis technische Vorgaben',
        text:
            '<strong>Hinweis</strong>: Werden die technischen Vorgaben nicht eingehalten, verringert sich gemäß EEG der gesetzliche Vergütungsanspruch auf den Monatsmarktwert.',
    },
    imBilling: {
        label: 'Abrechnung',
        options: {
            invoice: {
                label: 'Rechnung',
            },
            sepa: {
                label: 'SEPA',
            },
        },
    },
    imDirectDebitMandate: {
        text:
            "<strong style='font-size: 20px;'>SEPA-Lastschriftenmandat</strong><br><br> Hiermit ermächtigt der Kontoinhaber die EWE NETZ GmbH, Cloppenburger Str. 302, 26133 Oldenburg (Gläubiger-Identifikationsnummer: DE13ZZZ00000023597), fällige Beträge von seinem Konto wiederkehrend mittels Lastschrift einzuziehen. Zugleich wird das Kreditinstitut angewiesen, die von EWE NETZ auf das Konto gezogenen Lastschriften einzulösen. Den Nachweis des SEPA-Lastschriftmandates gegenüber der Bank erbringt die EWE NETZ GmbH. Dieses Mandat gilt für alle bestehenden und zukünftigen Aufträge.<br><br><strong>Hinweis</strong>: Innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, kann die Erstattung des Lastschriftbetrages vom Kontoinhaber verlangt werden. Es gelten dabei die mit dem Kreditinstitut vereinbarten Bedingungen.<br><br>Änderungen der Bankverbindung sowie sonstige für dieses SEPA-Lastschriftmandat relevante Änderungen wird der Kontoinhaber EWE NETZ unverzüglich in Schriftform mitteilen.",
    },
    imIban: {
        label: 'IBAN',
        info:
            'Hiermit ermächtige ich die EWE NETZ GmbH, Cloppenburger Str. 302, 26133 Oldenburg (Gläubieridentifikationsnummer: DE13ZZZ00000023597), fällige Beträge von meinem Konto wiederkehrend mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der EWE NETZ GmbH auf mein Konto gezogenen Lastschriften einzulösen. Den Nachweis des SEPA-Lastschriftmandates gegenüber der Bank erbringt die EWE NETZ GmbH. Dieses Mandat gilt für alle bestehenden und zukünftigen Aufträge. <strong>Hinweis:</strong> innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, kann die Erstattung des Lastschriftbetrages vom Kontoinhaber verlangt werden. Es gelten dabei die mit dem Kreditinstitut vereinbarten Bedingungen.',
    },
    imCreditInstitutionName: {
        label: 'Name und Sitz des Kreditinstituts',
    },
    imAccountHolderName: {
        label: 'Vorname und Name Kontoinhaber',
    },
    imBillingDate: {
        label: 'Datum',
        info: 'Bitte geben Sie das Geburtsdatum im Format TT.MM.JJJJ ein.',
    },
};
