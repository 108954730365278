import { WebStorageStateStore, UserManagerSettings } from 'oidc-client-ts';

/* eslint-disable @typescript-eslint/naming-convention */
export const options: UserManagerSettings & {
    mode?: string;
    cidaas_version: number;
} = {
    authority: process.env.VUE_APP_CIDAAS_AUTHORITY,
    client_id: process.env.VUE_APP_CIDAAS_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_CIDAAS_REDIRECT_URI,
    post_logout_redirect_uri:
        process.env.VUE_APP_CIDAAS_POST_LOGOUT_REDIRECT_URI,
    popup_post_logout_redirect_uri:
        process.env.VUE_APP_CIDAAS_POPUP_POST_LOGOUT_REDIRECT_URI,
    silent_redirect_uri: process.env.VUE_APP_CIDAAS_SILENT_REDIRECT_URI,
    response_type: 'code',
    scope: 'openid email roles profile offline_access',
    mode: 'redirect',
    userStore: new WebStorageStateStore({
        store: localStorage,
    }),
    cidaas_version: 3,
};
