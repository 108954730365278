import { MockService } from '../mockService';
import { headerContent } from './app/headerContent';
import { sideBarContent } from './app/sideBarContent';
import { footerContent } from './app/footerContent';
import { messagesContent } from './app/messagesContent';
import { enumsContent } from './app/enumsContent';
import { messageDialogueContent } from './app/messageDialogueContent';
import { applicationOverviewContent } from './app/pages/applicationOverviewContent';
import { thankYouContent } from './app/pages/thankYouContent';
import { adminContent } from './app/pages/adminContent';
import { toolsContent } from './app/pages/toolsContent';
import { applicationFormContent } from './form/applicationFormContent';
import { validationErrorsContent } from './form/validationErrorsContent';
import type { ContentRepositoryInterface } from '@/api/interfaces/content/contentRepositoryInterface';
import { staticImplements } from '@/decorators/staticImplements';
import { AppContent } from '@/api/interfaces/content/app/appContent';
import { FormContent } from '@/api/interfaces/content/form/formContent';

@staticImplements<ContentRepositoryInterface>()
export class ContentRepository {
    static fetchAppContent(): Promise<AppContent> {
        const content: AppContent = {
            headerContent,
            sideBarContent,
            pages: {
                adminContent,
                applicationOverviewContent,
                thankYouContent,
                toolsContent,
            },
            footerContent,
            messagesContent,
            enumsContent,
            messageDialogueContent,
        };

        const mockService = new MockService<AppContent>();
        return mockService.runServiceWithSuccess(content, 5);
    }
    static fetchFormContent(): Promise<FormContent> {
        const content: FormContent = {
            applicationFormContent,
            validationErrorsContent,
        };

        const mockService = new MockService<FormContent>();
        return mockService.runServiceWithSuccess(content, 5);
    }
}
