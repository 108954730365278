












import Vue, { PropType } from 'vue';
import LinkList from '@/components/molecules/linkList/LinkList.vue';
import { Align } from '@/enums/align';
import { Orientation } from '@/enums/orientation';
import type { FooterContent } from '@/api/interfaces/content/app/footerContent';
import { LinkHoverBehavior } from '@/enums/linkHoverBehavior';

export default Vue.extend({
    name: 'AppFooter',
    components: {
        LinkList,
    },
    props: {
        footerContent: {
            type: Object as PropType<FooterContent>,
            default: {},
        },
    },
    data: () => {
        return {
            align: Align.center,
        };
    },
    computed: {
        linkOrientations: () => {
            return Orientation.horizontal;
        },
        linkHoverBehavior: (): string =>
            LinkHoverBehavior.textWithHoverUnderline,
    },
});
