import Vue from 'vue';
import { WebAuth } from 'cidaas-javascript-sdk';
import { options } from '@/auth/index';
import { parseToken } from './tokenParsingService';
import Oidc from 'oidc-client-ts';

interface CidaasWindow extends Window {
    usermanager: Oidc.UserManager;
}

const cidaas = new WebAuth(options);

const storageKeys = {
    oidcUser: `user:${options.authority}:${options.client_id}`,
    returnUrl: 'dea.returnUrl',
};

interface CidaasUserInfo {
    /* eslint-disable @typescript-eslint/naming-convention */
    access_token: string;
    id_token: string;
    refresh_token: string;
    token_type: string;
    profile: {
        auth_time: number;
        email: string;
        locale: string;
        name: string;
        roles: string[];
        sub: string;
    };
    expires_at: number;
    expired: boolean;
    expires_in: number;
    /* eslint-enable @typescript-eslint/naming-convention */
}

interface CidaasRenewedToken {
    /* eslint-disable @typescript-eslint/naming-convention */
    access_token: string;
    id_token: string;
    refresh_token: string;
    token_type: string;
    /* eslint-enable @typescript-eslint/naming-convention */
}

export type Role =
    | 'DEA Admin'
    | 'DEA Debug'
    | 'DEA Dummy'
    | 'DEA User'
    | 'DEA NonexistingTestRole';

export interface AuthService {
    readonly isAuthenticated: boolean;
    readonly token: string | null;
    restore(): Promise<void>;
    authenticate(): Promise<void>;
    logout(): Promise<void>;
    handleLoginCallback(): Promise<void>;
    getMissingRoles(...requiredRoles: Role[]): Role[];
    hasRoles(...requiredRoles: Role[]): boolean;
}

class CidaasAuthService implements AuthService {
    private static readonly numberOfMsToPassBeforeTokenRenewal = 30000;
    private userInfo: CidaasUserInfo | null = null;
    private previousTokenRenewalTime: number | null = null;
    private tokenRenewalPromise: Promise<CidaasRenewedToken> | null = null;

    public async restore(): Promise<void> {
        this.userInfo = await this.getUserInfo();
    }

    public get isAuthenticated(): boolean {
        return !!this.userInfo && !this.userInfo.expired;
    }

    public get token(): string | null {
        return this.isAuthenticated && this.userInfo
            ? this.userInfo.id_token
            : null;
    }

    private getUserInfo(): Promise<CidaasUserInfo> {
        return cidaas.getUserInfo();
    }

    // the user of the OIDC user manager is not updated automatically when
    // renewing the token, so we have to set the renewed token by ourselves
    private async syncOidcUser(data: CidaasRenewedToken): Promise<void> {
        const storedUserInfoString = await options.userStore?.get(
            storageKeys.oidcUser,
        );
        const storedUserInfo = storedUserInfoString
            ? (JSON.parse(storedUserInfoString) as CidaasUserInfo)
            : null;

        if (!storedUserInfo) {
            throw new Error('could not read user from session storage');
        }

        storedUserInfo.access_token = data.access_token;
        storedUserInfo.id_token = data.id_token;
        storedUserInfo.refresh_token = data.refresh_token;
        storedUserInfo.token_type = data.token_type;
        storedUserInfo.expires_at = parseToken<{ exp: number }>(
            data.access_token,
        ).exp;

        // store updated user and refresh user info
        await options.userStore?.set(
            storageKeys.oidcUser,
            JSON.stringify(storedUserInfo),
        );
    }

    public async renewToken(): Promise<void> {
        if (!this.userInfo) {
            throw new Error('not authenticated');
        }

        // do not renew token, if it was just created
        const currentTime = new Date().getTime();
        if (
            this.previousTokenRenewalTime !== null &&
            currentTime - this.previousTokenRenewalTime <
                CidaasAuthService.numberOfMsToPassBeforeTokenRenewal
        ) {
            return;
        }
        this.previousTokenRenewalTime = currentTime;

        // always get the current user info in case it was updated in other tabs
        this.userInfo = await this.getUserInfo();

        // await any open token renewal promises to avoid sending outdated refresh tokens
        const refreshToken = this.tokenRenewalPromise
            ? (await this.tokenRenewalPromise).refresh_token
            : this.userInfo.refresh_token;

        this.tokenRenewalPromise = cidaas.renewToken({
            /* eslint-disable @typescript-eslint/naming-convention */
            refresh_token: refreshToken,
            user_agent: navigator.userAgent,
            ip_address: '',
            accept_language: navigator.language,
            lat: '',
            lng: '',
            finger_print: '',
            referrer: location.href,
            pre_login_id: '',
            login_type: '',
            device_code: '',
            /* eslint-enable @typescript-eslint/naming-convention */
        }) as Promise<CidaasRenewedToken>;

        const renewedToken = await this.tokenRenewalPromise;
        if (!renewedToken || !renewedToken.access_token) {
            throw new Error('could not renew token');
        }

        await this.syncOidcUser(renewedToken);
        this.userInfo = await this.getUserInfo();
        this.tokenRenewalPromise = null;
    }

    public async authenticate(): Promise<void> {
        if (this.isAuthenticated) {
            await this.renewToken();
        } else {
            await this.login();
        }
    }

    private async login(): Promise<void> {
        // store return URL in session
        sessionStorage.setItem(storageKeys.returnUrl, window.location.href);
        this.clearStaleState();

        // forward to cidaas login page
        await cidaas.loginWithBrowser();
    }

    public async logout(): Promise<void> {
        if (!this.userInfo) {
            return;
        }

        await cidaas.logout();
        if (options.post_logout_redirect_uri) {
            window.location.href = options.post_logout_redirect_uri;
        }
    }

    public async handleLoginCallback(): Promise<void> {
        await cidaas.loginCallback();

        // forward to return URL and release it from storage
        const returnUrl = sessionStorage.getItem(storageKeys.returnUrl);
        sessionStorage.removeItem(storageKeys.returnUrl);

        if (returnUrl) {
            window.location.href = returnUrl;
        }
    }

    public getMissingRoles(...requiredRoles: Role[]): Role[] {
        if (!this.userInfo) {
            throw new Error('not authenticated');
        }

        const userRoles = this.userInfo.profile.roles;

        return requiredRoles.filter(
            (requiredRole) => !userRoles.includes(requiredRole),
        );
    }

    public hasRoles(...requiredRoles: Role[]): boolean {
        return this.getMissingRoles(...requiredRoles).length === 0;
    }

    private clearStaleState() {
        // Everytime logout is triggered we get two entries in local storage (oidc.<32-digit-id>) (one for logout, one for signin) but only one is cleaned up after redirect back to dea page (pretty sure signin entry gets removed).
        // This abandoned (logout) entry can indicate an oidc workflow which was not completed.
        // Since I cannot find any wrong implementation lets clear stale states (left-over entries) here.

        // window.usermanager is set by cidaas
        // clearStaleState is using staleStateAge which is set by oidc config
        (window as CidaasWindow).usermanager?.clearStaleState();
    }
}

export const authService = Vue.observable<AuthService>(new CidaasAuthService());
