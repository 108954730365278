import { toFixed } from '@/utilities/math';

export function readableFileSize(bytes: number): string {
    const toFixedTwoFractions = toFixed(2);

    if (bytes === 0) {
        return '0 B';
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const unit = ['B', 'KB', 'MB', 'GB', 'TB'][i];

    return `${toFixedTwoFractions(bytes / Math.pow(1024, i))} ${unit}`;
}
