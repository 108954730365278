import { httpClient } from '../httpClient/httpClient';
import type { ConfigurationRepositoryInterface } from '@/api/interfaces/configuration/configurationRepositoryInterface';
import { staticImplements } from '@/decorators/staticImplements';
import { FormConfiguration } from '@/api/interfaces/configuration/form/formConfiguration';

@staticImplements<ConfigurationRepositoryInterface>()
export class ConfigurationRepository {
    static fetchFormConfiguration(): Promise<FormConfiguration> {
        return httpClient.get<FormConfiguration>(
            '/api/dea/v1/configuration/form',
        );
    }
}
