



























import Vue from 'vue';
import AppRouterLink from '@/components/atoms/appRouterLink/AppRouterLink.vue';
import HamburgerMenu from '@/components/atoms/hamburgerMenu/HamburgerMenu.vue';
import { authService } from '@/services/authService';

export default Vue.extend({
    name: 'MetaNavigation',
    components: {
        AppRouterLink,
        HamburgerMenu,
    },
    props: {
        adminLinkText: {
            type: String,
            default: null,
        },
        toolsLinkText: {
            type: String,
            default: null,
        },
        toOverviewLinkText: {
            type: String,
            default: null,
        },
        logoutLinkText: {
            type: String,
            default: null,
        },
        hamburgerMenuOpen: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showAdminLinks(): boolean {
            return (
                authService.isAuthenticated && authService.hasRoles('DEA Admin')
            );
        },
    },
});
