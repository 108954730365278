












































import Vue from 'vue';
import { contentServices } from '@/services/contentService';
import AppHeader from '@/components/organisms/appHeader/AppHeader.vue';
import AppFooter from '@/components/organisms/appFooter/AppFooter.vue';
import SideBar from '@/components/organisms/sideBar/SideBar.vue';
import { AppContent } from '@/api/interfaces/content/app/appContent';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from './enums/spinnerState';
import MessageDialogue from '@/components/molecules/messageDialogue/MessageDialogue.vue';
import router from './router';
import { authService } from './services/authService';
import { isServiceError } from '@/api/interfaces/typeGuards';

export default Vue.extend({
    components: {
        AppHeader,
        AppFooter,
        SideBar,
        SpinnerArea,
        MessageDialogue,
    },
    data: () => {
        return {
            spinner: SpinnerState.opaque,
            content: null as AppContent | null,
            sideBarActive: false,
        };
    },
    computed: {
        isStaticRoute(): boolean {
            return (
                this.$router.options?.routes?.some(
                    (route) =>
                        route.meta?.staticRoute &&
                        route.path === this.$route.path,
                ) ?? false
            );
        },
    },
    async mounted() {
        // do not open app, if the user is not authenticated
        await authService.restore();
        if (!authService.isAuthenticated) {
            return;
        }

        try {
            this.content = await contentServices.app.requestContentOnce();
        } catch (e) {
            if (isServiceError(e)) {
                router.push('/fehler');
            }
        } finally {
            this.spinner = SpinnerState.off;
        }
    },
    methods: {
        toggleSideMenu(): void {
            this.sideBarActive = !this.sideBarActive;
        },
    },
});
