import {
    ALL_ADDRESSES_API_BAD_RESPONSE_TYPES,
    AddressesApiBadResponse,
} from '@/types/forms/shared/addressesApi/addressesApiBadResponse';
import { ServiceError } from './serviceError';

export const isServiceError = function (
    arg: ServiceError,
): arg is ServiceError {
    return arg.message !== undefined;
};

export const isAddressesApiBadResponse = function (
    arg: unknown,
): arg is AddressesApiBadResponse {
    if (arg === null || typeof arg !== 'string') {
        return false;
    }

    return (ALL_ADDRESSES_API_BAD_RESPONSE_TYPES as ReadonlyArray<
        string
    >).includes(arg);
};
