






import Vue, { PropType } from 'vue';
import AppLink from '@/components/atoms/appLink/AppLink.vue';
import AppImage from '@/components/atoms/appImage/AppImage.vue';
import { ImageLinkContent } from '@/api/interfaces/content/app/imageLinkContent';

export default Vue.extend({
    name: 'AppLogo',
    components: {
        AppLink,
        AppImage,
    },
    props: {
        content: {
            type: Object as PropType<ImageLinkContent>,
            required: true,
        },
    },
});
