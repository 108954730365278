const alwaysInDtoAttributes: string[] = [];

/**
 * Writes a field value to dto, even if the field is disabled.
 */
export function alwaysInDtoAttribute() {
    return (target: unknown, propertyKey: string): void => {
        alwaysInDtoAttributes.push(
            alwaysInDtoAttributeGenerateName(propertyKey, target),
        );
    };
}

export function isAlwaysInDtoAttribute(
    propertyKey: string,
    target: unknown = null,
): boolean {
    return alwaysInDtoAttributes.includes(
        alwaysInDtoAttributeGenerateName(propertyKey, target),
    );
}

export function alwaysInDtoAttributeGenerateName(
    propertyKey: string,
    target: unknown = null,
): string {
    let name = propertyKey;
    if (typeof target === 'object' && target !== null) {
        const namespace = target.constructor.name;
        if (namespace) {
            name = `${namespace}.${name}`;
        }
    }
    return name;
}
