import { defaults as _defaults } from 'lodash';
import {
    FormElement,
    FormElementConfig,
    formElementDefaultConfig,
} from './formElement';

export const formGroupDefaultConfig = {
    ...formElementDefaultConfig,
};

export class FormGroup extends FormElement {
    public constructor(config?: FormElementConfig) {
        const resolvedConfig = _defaults(
            config || ({} as FormElementConfig),
            formGroupDefaultConfig,
        );
        super(resolvedConfig);
    }
}
