import { MockService } from '../mockService';
import type { FileRepositoryInterface } from '@/api/interfaces/file/fileRepositoryInterface';
import { staticImplements } from '@/decorators/staticImplements';
import { UploadedFileInfo } from '@/api/interfaces/file/uploadedFileInfo';
import { FileUploadResponse } from '@/api/interfaces/file/fileUploadResponse';
import { FileDownloadResponse } from '@/api/interfaces/file/fileDownloadResponse';
import { ServiceSuccess } from '@/api/interfaces/serviceSuccess';

@staticImplements<FileRepositoryInterface>()
export class FileRepository {
    static uploadFile(
        file: File,
        scId: string,
        /* eslint-disable @typescript-eslint/no-unused-vars */
        fileName: string,
        applicationId: string,
        /* eslint-enable @typescript-eslint/no-unused-vars */
    ): Promise<FileUploadResponse> {
        const mockService = new MockService<FileUploadResponse>();

        if (file.name === 'failUpload.pdf') {
            return mockService.runServiceWithFailure(1000);
        }

        const response = {
            fileInfo: {
                scId: scId,
                id: 'unique',
                name: file.name,
                size: file.size,
            } as UploadedFileInfo,
        };

        return mockService.runServiceWithSuccess(response, 1000);
    }

    static async downloadFile(
        fileId: string,
        applicationId: string,
    ): Promise<FileDownloadResponse> {
        const mockService = new MockService<FileDownloadResponse>();

        const response: FileDownloadResponse = {
            fileName: `Dummy file ${fileId}`,
            bytes: btoa(`File-ID: ${fileId}, Application-ID: ${applicationId}`),
        };

        return mockService.runServiceWithSuccess(response, 1000);
    }

    static deleteFile(fileInfo: UploadedFileInfo): Promise<ServiceSuccess> {
        const mockService = new MockService<ServiceSuccess>();

        if (fileInfo.name === 'failDeleteWithException.pdf') {
            return mockService.runServiceWithFailure(1000);
        }

        return mockService.runServiceWithSuccess({ message: 'OK' }, 1000);
    }
}
