










import { ImageContent } from '@/api/interfaces/content/app/imageContent';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'AppImage',
    props: {
        content: {
            type: Object as PropType<ImageContent>,
            required: true,
        },
    },
});
