import { PlantPerformanceFormStepContent } from '@/api/interfaces/content/form/steps/plantPerformanceStepContent';
import { photovoltaicContent } from './plantPerformance/photovoltaic';
import { inverterContent } from './plantPerformance/inverter';
import { storageContent } from './plantPerformance/storage';
import { generatorContent } from './plantPerformance/generator';

export const plantPerformanceContent: PlantPerformanceFormStepContent = {
    stepHeading: 'Anlagenleistung',
    buttonNext: {
        text: 'Weiter',
    },
    ...photovoltaicContent,
    ...inverterContent,
    ...storageContent,
    ...generatorContent,
    certificateHint: {
        label:
            'Die steckerfertige Erzeugungsanlage entspricht der VDE-AR-N 4105 - Erzeugungsanlagen am Niederspannungsnetz. Die Erzeugungseinheit und der Netz- und Anlagenschutz verfügen als Nachweis über ein Zertifikat, welches die Einhaltung der Anwendungsregel bestätigt. Auf Nachfrage lege ich EWE NETZ diese Zertifikate vor.',
        summaryLabel:
            'Die steckerfertige Erzeugungsanlage entspricht der VDE-AR-N 4105 - Erzeugungsanlagen am Niederspannungsnetz.',
    },
    overallNominalPowerNotMatchingTotalPlantPerformanceValueTypeWarning: {
        text:
            'Die Leistung entspricht nicht ihrer Angabe auf dem Formular Erzeugungsart. Bitte prüfen Sie ihre Angabe oder korrigieren Sie den Leistungswert.',
    },
};
