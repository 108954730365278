import { EntryListContent } from '@/api/interfaces/content/common/entryListContent';

export const validationErrorsContent: EntryListContent = {
    alphaNum:
        'Der Wert des Feldes darf nur Zahlen und Buchstaben ohne Sonderzeichen enthalten.',
    apparentPowerMustBeBiggerThanActualPower:
        'Die Summe der Wirkleistung kann nie größer sein als die Summe der Scheinleistung.',
    between:
        'Der Wert des Feldes muss in einem Bereich von {{ min }} bis {{ max }} liegen.',
    checked: 'Bitte aktivieren Sie diese Checkbox.',
    date: 'Für dieses Feld ist nur das Datumsformat TT.MM.JJJJ erlaubt.',
    email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    fileType:
        'Die Datei ist ungültig, bitte beachten Sie die Dateitypbeschränkung.',
    float: 'Für dieses Feld sind lediglich numerische Werte erlaubt.',
    gt:
        'Der Wert des Feldes muss größer als der Wert {{ comparedValue }} sein.',
    houseNumber: 'Die eingegebene Hausnummer enthält ungültige Zeichen.',
    iban: 'Bitte geben Sie eine gültige IBAN ein',
    inDateRange:
        'Das Datum ist nur zwischen dem {{ minDate }} und dem {{ maxDate }} gültig.',
    integer: 'Für dieses Feld sind lediglich ganzzahlige Werte erlaubt.',
    length: 'Der Wert des Feldes muss genau {{ length }} Zeichen lang sein.',
    lt:
        'Der Wert des Feldes muss kleiner als der Wert {{ comparedValue }} sein.',
    maxApparentPowerPlugInGeneratingPlant:
        'Der Anschluss einer steckerfertigen Erzeugungsanlage ist nur bis zu einer Scheinleistung von {{ max }} kVA zulässig.',
    maxDate: 'Das Datum ist nur bis zum {{ maxDate }} gültig.',
    maxDateNow: 'Das Datum darf nicht in der Zukunft liegen.',
    maxFileSize:
        'Die Datei ist zu groß, bitte beachten Sie die Dateigrößenbeschränkung.',
    maxLength:
        'Der Wert des Feldes darf nicht länger als {{ max }} Zeichen sein.',
    maxNominalPowerPlugInGeneratingPlant:
        'Der Anschluss einer steckerfertigen Erzeugungsanlage ist nur bis zu einer Modulleistung von {{ max }} kW zulässig.',
    maxNumber: 'Die eingegebene Zahl darf nicht größer als {{ max }} sein.',
    maxValue:
        'Der Wert des Feldes ist größer als der erlaubte Maximalwert von {{ max }}.',
    minDate: 'Das Datum ist nur ab dem {{ minDate }} gültig.',
    minLength:
        'Der Wert des Feldes darf nicht kürzer als {{ min }} Zeichen sein.',
    minNumber: 'Die eingegebene Zahl darf nicht kleiner als {{ min }} sein.',
    minValue:
        'Der Wert des Feldes ist kleiner als der erlaubte Minimalwert von {{ min }}.',
    mustBeLowerThanFeedInPowerRecommendation:
        'Die begrenzte Einspeiseleistung kann nicht größer sein als der berechnete Vorschlagswert.',
    mustBeLowerThanOrEqualToSelfGenerationQuantity:
        'Der Wert Eigenversorgungsmenge darf nie größer sein als die Eigenerzeugungsmenge.',
    mustBeLowerThanToExistingPlantsTotalPower:
        'Die Gesamtleistung die außer Betrieb genommen wird darf nie größer sein als die Gesamtleistung (Bestand).',
    mustBeNoMoreThan20PercentAboveSuggestion:
        'Der Wert darf maximal 20% über dem Vorschlagswert liegen',
    name: 'Der eingegebene Name enthält ungültige Zeichen.',
    number: 'Bitte geben Sie eine Zahl ein.',
    orderNumber:
        'Für die Eingabe der Auftragsnummer sind ausschließlich Zahlen zulässig.',
    phoneNumber:
        'Für die Eingabe einer Telefonnummer sind ausschließlich Zahlen ohne Leerzeichen zulässig.',
    positive: 'Der Wert des Feldes darf nicht negativ sein.',
    required: 'Die Eingabe des Feldes ist verpflichtend.',
    requiredDate: 'Die Eingabe des Datums ist verpflichtend.',
    requiredIf: 'Die Eingabe des Feldes ist verpflichtend.',
    requiredIfAny: 'Die Felder müssen vollständig ausgefüllt werden.',
    unbalanced:
        'Die Unsymmetrie von {{ max }} {{ unit }} darf nicht überschritten werden.',
    zip:
        'Bitte geben Sie eine für das ausgewählte Land gültige Postleitzahl ein.',
};
