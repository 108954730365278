import { PowerConnectionFormStepContent } from '@/api/interfaces/content/form/steps/powerConnectionFormStepContent';

export const powerConnectionContent: PowerConnectionFormStepContent = {
    stepHeading: 'Netzanschluss',
    buttonNext: {
        text: 'Weiter',
    },
    doesConnectionExist: {
        label: 'Ist der Strom-Netzanschluss bereits vorhanden?',
        summaryLabel: 'Strom-Netzanschluss bereits vorhanden',
        options: {
            yes: {
                label: 'Ja',
            },
            ordered: {
                label: 'Beauftragt',
            },
            no: {
                label: 'Nein',
            },
        },
    },
    whichVoltageLevel: {
        label:
            'In welcher Spannungsebene ist/wird Ihr Netzanschluss angeschlossen?',
        summaryLabel:
            'Ihr Netzanschluss ist/wird angeschlossen in Spannungsebene',
        info:
            'Wohnhäuser oder kleine Gewerbekunden verfügen in der Regel über einen 230/400 V Niederspannungsnetzanschluss. Haben Sie eine eigene Transformatorenstation? Dann handelt es sich um einen 20 kV Mittelspannungsnetzanschluss.',
        options: {
            lowVoltage: {
                label: 'Niederspannung',
                image: {
                    path:
                        'https://www.ewe-netz.de/~/media/ewe-netz/teaser-bilder/einspeiser/strom-preis-einspeiser_440x285.jpg?h=285&la=de-DE',
                },
            },
            mediumVoltage: {
                label: 'Mittelspannung',
                image: {
                    path:
                        'https://www.ewe-netz.de/~/media/ewe-netz/teaser-bilder/einspeiser/strom-preis-einspeiser_440x285.jpg?h=285&la=de-DE',
                },
            },
            unknown: {
                label: 'Unbekannt',
                image: {
                    path:
                        'https://www.ewe-netz.de/~/media/ewe-netz/teaser-bilder/einspeiser/strom-preis-einspeiser_440x285.jpg?h=285&la=de-DE',
                },
            },
        },
    },
    hasExistingPlants: {
        label:
            'Am vorhandenen Netzanschluss sind bereits Erzeugungsanlagen vorhanden',
    },
    existingPlantsTotalPower: {
        label: 'Gesamtleistung (Bestand)',
        unit: 'kW',
        info:
            '<ul><li>PV: Wechselrichterleistung</li><li>Speicher: Wechselrichterleistung (nur bei Einspeisung ins öffentliche Netz)</li><li>Alle anderen: Generatorleistung</li></ul>',
    },
    repoweringPlanned: {
        label: 'Repowering ist vorgesehen',
    },
    repoweringTotalPower: {
        label: 'Gesamtleistung die außer Betrieb genommen wird',
        unit: 'kW',
        info:
            'Planen Sie im Zuge der Errichtung einen R&uuml;ckbau der Bestandsanlage? Bitte geben Sie die Leistung an, die au&szlig;er Betrieb genommen wird.',
    },
    feedInPowerShallBeLimitedByAController: {
        label:
            'Die Einspeiseleistung soll mit Hilfe eines Einspeiseregler begrenzt werden (PAV, E)',
    },
    feedInPower: {
        label: 'Einspeisung PAV, E',
        unit: 'kW',
        info:
            'Vereinbarte Anschlusswirkleistung für Einspeisung am Netzanschlusspunkt (PAV, E)',
    },
    plannedReferencePower: {
        label: 'Geplante Bezugsleistung PAV, B',
        unit: 'kW',
    },
    ofWhichOwnUseGeneratingPlant: {
        label: 'Davon Eigenbedarf der Erzeugungsanlage PAV, B',
        unit: 'kW',
    },
    ownUseExceedsPlannedPowerWarning: {
        text:
            'Der Eigenbedarf der Erzeugunsanlage (PAV, B) darf nicht höher sein als die geplante Bezugsleistung (PAV, B).',
    },
    noConnectionAvailable: {
        text:
            'Sie haben noch keinen Stromanschluss? Diesen können Sie ganz einfach mit unserer Online Formularstrecke unter <a href="https://www.ewe-netz.de/privatkunden/strom/ihr-hausanschluss">https://www.ewe-netz.de/privatkunden/strom/ihr-hausanschluss</a> beauftragen.',
    },
    reactivePowerCompensationSystemExists: {
        label: 'Eine Blindleistungskompensationsanlage ist vorhanden',
    },
    unitCapacity: {
        label: 'Leistung der Anlage',
        unit: 'kVar',
    },
    unitIsPrototype: {
        label: 'Bei der Anlage handelt es sich um einen Prototypen',
    },
    buildingPowerDemand: {
        label: 'Baustrombedarf',
    },
    requiredPower: {
        label: 'Benötigte Leistung',
        unit: 'kW',
    },
    fromWhen: {
        label: 'Ab wann?',
        summaryLabel: 'Ab',
    },
};
