import { ControllableConsumptionDevicesFormStepContent } from '@/api/interfaces/content/form/steps/controllableConsumptionDevicesFormStepContent';

export const controllableConsumptionDevicesContent: ControllableConsumptionDevicesFormStepContent = {
    stepHeading: 'Steuerbare Verbrauchseinrichtungen gem. §14a EnWG',
    buttonNext: {
        text: 'weiter',
    },
    controllableConsumptionDevicesInfo: {
        text:
            'Stromspeicher mit einer elektrischen Leistung von über 4,2 kW gelten als steuerbare Verbrauchseinrichtung gem. §14a EnWG. Konkret bedeutet dies, dass die Leistung Ihres Geräts im Falle einer Engpasssituation im Stromnetz, durch den Netzbetreiber reduziert werden darf. Diese Regelung muss gemäß <a href="https://www.bundesnetzagentur.de/DE/Beschlusskammern/1_GZ/BK6-GZ/2022/BK6-22-300/Beschluss/BK6-22-300_Beschluss_20231127.pdf?__blob=publicationFile&amp;v=1">Festlegung der Bundesnetzagentur</a> auch für Geräte ohne Netzbezug umgesetzt werden, wenn diese von ihrer technischen Auslegung her grundsätzlich in der Lage sind, den Ladevorgang mit Auswirkung auf den netzwirksamen Leistungsbezug durchzuführen. <br />\n<br />\nIm Gegenzug können Sie von vergünstigten Netzentgelten profitieren. Sie werden im ersten Schritt dem sogenannten Modul 1, der pauschalen Reduzierung des Netzentgelts zugeordnet. Wenn Sie Modul 2, eine prozentuale Arbeitspreisreduzierung in Anspruch nehmen möchten, dann können Sie das über Ihren Stromlieferanten beauftragen. Die Reduzierung der netzwirksamen Leistung wird über ein intelligentes Messsystem sowie einer Steuerbox umgesetzt. Stimmen Sie sich hierzu auch gerne mit Ihrem Installateur ab.<br />\n<br />\nZur Umsetzung des §14a EnWG gelten folgende <a href="https://www.ewe-netz.de/-/media/ewe-netz/downloads/vereinbarung-ber-die-netzorientierte-steuerung-gem-parag-14a-enwg.pdf">Bedingungen</a> sowie <a href="https://www.ewe-netz.de/-/media/ewe-netz/downloads/2023_12_29_anlage-1-zum-beschluss-bk622300-vom-27112023.pdf">Anlage 1</a> zum Beschluss BK6-22-300 vom 27.11.2023.',
    },
    gridControlConditionsAccepted: {
        label:
            'Ich habe die Bedingungen zur netzorientierten Steuerung gelesen und stimme diesen zu.',
    },
    gridControlImplementationPreference: {
        label:
            'Wie möchten Sie die netzorientierte Steuerung gem. §14a EnWG technisch umsetzen?',
        info:
            'Bei der Direktsteuerung wird der Wert des netzwirksamen Leistungsbezugs Leistungsreduzierung direkt an die steuerbare Verbrauchseinrichtung weitergegeben. Bei der Steuerung über das Energiemanagementsystem (EMS) wird der Wert des netzwirksamen Leistungsbezugs an ein EMS übergeben. Das EMS hat den Wert zu übernehmen und die kundenanlageninterne Koordination des Bezugs der steuerbaren Verbrauchseinrichtungen sowie die kundenanlageninterne Nutzung eventuell dort erzeugter Energiemengen sicherzustellen.',
        options: {
            directControl: {
                label: 'Direktansteuerung',
            },
            emsControl: {
                label: 'Steuerung mittels Energiemanagementsystem',
            },
        },
    },
    controllableConsumptionFacilitiesRequirementInfo: {
        text:
            'Hinweis:<br />\nGemäß EEG §9 sind Betreiber von steuerbaren Verbrauchseinrichtungen dazu verpflichtet am Netzanschluss vorhandene Erzeugungsanlagen mit technischen Einrichtungen zur Fernsteuerbarkeit auszustatten. Ausgenommen von dieser Regelung sind steckerfertige Solargeräte. Weitere Informationen zur technischen Umsetzung für ihren Errichter finden Sie <a href="https://www.ewe-netz.de/-/media/ewe-netz/downloads/2023_12_19_informationen_einbau_stb-14a-mit-eeg-anlage.pdf">hier</a>.',
    },
};
