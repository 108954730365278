

















import Vue, { PropType } from 'vue';
import AppLink from '@/components/atoms/appLink/AppLink.vue';
import { Align } from '@/enums/align';
import { Orientation } from '@/enums/orientation';
import type { Link } from '@/api/interfaces/content/app/link';
import { LinkHoverBehavior } from '@/enums/linkHoverBehavior';

export default Vue.extend({
    name: 'LinkList',
    components: {
        AppLink,
    },
    props: {
        links: {
            type: Array as PropType<Link[]>,
            default: () => [],
        },
        align: {
            type: String as PropType<Align>,
            default: Align.left,
        },
        orientation: {
            type: String as PropType<Orientation>,
            default: Orientation.vertical,
        },
        hoverBehavior: {
            type: String as PropType<LinkHoverBehavior>,
            default: LinkHoverBehavior.default,
        },
    },
    computed: {
        orientationListItemClass: function () {
            return this.orientation == Orientation.horizontal
                ? 'inline-block'
                : 'block';
        },
    },
    methods: {},
});
