import type { AdminRepositoryInterface } from '@/api/interfaces/admin/adminRepository';
import { staticImplements } from '@/decorators/staticImplements';
import { httpClient } from '../httpClient/httpClient';
import { ServiceSuccess } from '@/api/interfaces/serviceSuccess';
import { AdminRepository as MockRepository } from '@/api/mock/admin/adminRepository';
import { AdminGetApplicationsResponse } from '@/api/interfaces/admin/adminGetApplicationsResponse';
import { DocumentDownloadResponse } from '@/api/interfaces/document/documentDownloadResponse';
import { AdminDocumentId } from '@/api/interfaces/admin/adminDocumentId';
import { AdminExportType } from '@/api/interfaces/admin/adminExportType';
import { AdminUpdateDataModel } from '@/types/app/admin/adminUpdateDataModel';

@staticImplements<AdminRepositoryInterface>()
export class AdminRepository {
    static fetchApplications(
        adminUpdateData: AdminUpdateDataModel,
    ): Promise<AdminGetApplicationsResponse> {
        const {
            query,
            offset,
            limit,
            dateSentAtStart,
            dateSentAtEnd,
            statuses,
        } = adminUpdateData;

        return httpClient.get<AdminGetApplicationsResponse>(
            '/api/dea/v1/admin/application',
            {
                params: {
                    query,
                    offset,
                    limit,
                    dateSentAtStart,
                    dateSentAtEnd,
                    statuses,
                },
            },
        );
    }

    static deleteApplication(applicationId: string): Promise<ServiceSuccess> {
        return httpClient.delete<ServiceSuccess>(
            `/api/dea/v1/admin/application/${applicationId}`,
        );
    }

    static async downloadDocument(
        documentId: AdminDocumentId,
        applicationId: string,
        applyMigrations = true,
        dataOnly?: boolean,
    ): Promise<DocumentDownloadResponse> {
        if (applicationId === 'kitchenSink') {
            // allow usage of kitchen sink, without real application
            return MockRepository.downloadDocument(documentId, applicationId);
        }

        if (dataOnly === null || dataOnly === undefined) {
            dataOnly = false;
        }

        const url = (() => {
            switch (documentId) {
                case 'zip':
                    return `/api/dea/v1/admin/application/${applicationId}/zip?applyMigrations=${applyMigrations}`;
                case 'csv':
                    return `/api/dea/v1/admin/application/${applicationId}/csv?applyMigrations=${applyMigrations}`;
                case 'json':
                    return `/api/dea/v1/admin/application/${applicationId}/json?applyMigrations=${applyMigrations}&dataOnly=${dataOnly}`;
            }
        })();

        return httpClient.get<DocumentDownloadResponse>(url);
    }

    static migrateApplicant(
        applicationIds: string[],
        targetApplicantId: string,
    ): Promise<ServiceSuccess> {
        return httpClient.post<ServiceSuccess>(
            `/api/dea/v1/admin/migrateApplicant`,
            {
                data: {
                    applicationIds,
                    targetApplicantId,
                },
            },
        );
    }

    static async export(
        exportType: AdminExportType,
        adminUpdateData: AdminUpdateDataModel,
    ): Promise<DocumentDownloadResponse> {
        const {
            query,
            offset,
            limit,
            dateSentAtStart,
            dateSentAtEnd,
            statuses,
        } = adminUpdateData;

        return httpClient.get<DocumentDownloadResponse>(
            `/api/dea/v1/admin/export`,
            {
                params: {
                    query,
                    offset,
                    limit,
                    dateSentAtStart,
                    dateSentAtEnd,
                    statuses,
                    exportType,
                },
            },
        );
    }
}
