import { staticImplements } from '@/decorators/staticImplements';
import { DocumentRepositoryInterface } from '@/api/interfaces/document/documentRepositoryInterface';
import { DocumentDownloadResponse } from '@/api/interfaces/document/documentDownloadResponse';
import { httpClient } from '../httpClient/httpClient';
import { DocumentRepository as DummyDocumentRepository } from '../../mock/document/documentRepository';
import { DocumentId } from '@/api/interfaces/document/documentId';

@staticImplements<DocumentRepositoryInterface>()
export class DocumentRepository {
    static async downloadDocument(
        documentId: DocumentId,
        applicationId: string,
    ): Promise<DocumentDownloadResponse> {
        if (applicationId === 'kitchenSink') {
            // allow usage of kitchen sink, without real application
            return DummyDocumentRepository.downloadDocument(
                documentId,
                applicationId,
            );
        }

        const url = (() => {
            switch (documentId) {
                case 'applicationDocumentsZip':
                    return `/api/dea/v1/application/${applicationId}/zip`;
            }
        })();

        return httpClient.get<DocumentDownloadResponse>(url);
    }
}
