















import { SpinnerState } from '@/enums/spinnerState';
import { SpinnerSize } from '@/enums/spinnerSize';
import { PropType } from 'vue';

export default {
    name: 'SpinnerArea',
    props: {
        state: {
            type: String as PropType<SpinnerState>,
            default: SpinnerState.off,
        },
        size: {
            type: String as PropType<SpinnerSize>,
            default: SpinnerSize.large,
        },
    },
    computed: {
        stateOpaque: (): SpinnerState => SpinnerState.opaque,
        stateTransparent: (): SpinnerState => SpinnerState.transparent,
        stateOff: (): SpinnerState => SpinnerState.off,
        sizeSmall: (): SpinnerSize => SpinnerSize.small,
        sizeLarge: (): SpinnerSize => SpinnerSize.large,
    },
};
