










import Vue from 'vue';
import { ColorScheme } from '@/enums/colorScheme';

export default Vue.extend({
    name: 'AppTitle',
    props: {
        colorScheme: {
            type: String,
            default: ColorScheme.primary,
        },
        title: {
            type: String,
            default: null,
        },
    },
});
