import { render, staticRenderFns } from "./MetaNavigation.vue?vue&type=template&id=3fd36585&scoped=true&"
import script from "./MetaNavigation.vue?vue&type=script&lang=ts&"
export * from "./MetaNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./MetaNavigation.vue?vue&type=style&index=0&id=3fd36585&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fd36585",
  null
  
)

export default component.exports