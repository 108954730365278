import '@/styles/_tailwind.css';
import '@/styles/_icons.css';
import '@/styles/_typo.css';
import './polyfills';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@/vue-defaults';

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
