

























import Vue from 'vue';
import AppLayer from '@/components/molecules/appLayer/AppLayer.vue';
import AppContent from '@/components/atoms/appContent/AppContent.vue';
import AppButton from '@/components/atoms/appButton/AppButton.vue';
import { dialogueMessageService } from '@/services/dialogueMessageService';
import router from '@/router';

export default Vue.extend({
    name: 'MessageDialogue',
    components: {
        AppButton,
        AppContent,
        AppLayer,
    },
    props: {
        closeDialogueLabel: {
            type: String,
            default: null,
        },
        newApplicationLabel: {
            type: String,
            default: null,
        },
        safetyRoute: {
            type: String,
            default: '/',
        },
        toSafetyLabel: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            dialogueMessageService,
        };
    },
    computed: {
        show(): boolean {
            return dialogueMessageService.visibility;
        },
    },
    methods: {
        hideLayer(): void {
            this.dialogueMessageService.hide();
        },
        newApplication(): void {
            this.hideLayer();
            router.push('/antrag');
        },
        toSafety(): void {
            this.hideLayer();
            router.push(this.safetyRoute);
        },
    },
});
