import {
    mapValues as _mapValues,
    isNil as _isNil,
    omitBy as _omitBy,
    isEmpty as _isEmpty,
    isArray as _isArray,
    isObject as _isObject,
    isString as _isString,
    ObjectIterator,
    Dictionary,
} from 'lodash';
import { Form } from '@/types/forms/form';
import { FormGroup } from '@/types/forms/formGroup';
import { FormField } from '@/types/forms/fields/formField';
import { FormStep } from '@/types/forms/formStep';
import { Forms } from '@/types/forms/forms';
import { isFormDtoAttribute } from '@/types/forms/formDtoAttribute';
import { FormList } from '@/types/forms/fields/formList';
import { isAlwaysInDtoAttribute } from '@/types/forms/alwaysinDtoAttribute';

/**
 * Maps values using lodash _mapValues and removes empty / undefined / null values.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function mapValuesAndClean<T extends object, TResult>(
    obj: T | null | undefined,
    callback: ObjectIterator<T, TResult>,
): Dictionary<TResult> {
    return _omitBy(_omitBy(_mapValues(obj, callback), _isNil), _isEmpty);
}

export type FormDto = { [identifier: string]: FormDto | FormDto[] | unknown };

/**
 * Walks through FormGroups of this FormStep / FormGroup and returns the values from the FormFields.
 * Recursively calls itself to handle sub-FormGroups.
 * @param disabled Needed to recursively disable FormGroups.
 */
function getFormGroupAndElementValues(
    formGroup: FormGroup,
    disabled = false,
): FormDto {
    const ret = {} as FormDto;
    for (const prop in formGroup) {
        if (Object.prototype.hasOwnProperty.call(formGroup, prop)) {
            const formGroupProp = formGroup[prop as keyof FormGroup];
            if (formGroupProp instanceof FormList) {
                if (disabled || formGroupProp.disabled) {
                    continue;
                }
                ret[prop] = formGroupProp.items.map((item) =>
                    getFormGroupAndElementValues(
                        item,
                        disabled || formGroup.disabled,
                    ),
                );
            } else if (formGroupProp instanceof FormGroup) {
                if (disabled || formGroupProp.disabled) {
                    continue;
                }
                const formGroupValues = getFormGroupAndElementValues(
                    formGroupProp,
                    disabled || formGroupProp.disabled,
                );
                ret[prop] = {
                    ...formGroupValues,
                };
            } else if (formGroupProp instanceof FormField) {
                if (
                    !isAlwaysInDtoAttribute(prop, formGroup) &&
                    (disabled || formGroupProp.disabled)
                ) {
                    continue;
                }
                ret[prop] = formGroupProp.value;
            }
        }
    }

    // remove empty arrays, empty Objects, null, undefined, empty strings
    return _omitBy(ret, function (value: unknown | unknown[]) {
        if (Array.isArray(value)) {
            return !value.length;
        }

        if (typeof value === 'object' && value != null) {
            return !Object.keys(value).length;
        }

        return value == null || value === '';
    });
}

/**
 * Walks through the form, calls "getFormGroupAndElementValues" for each FormStep to get the values from the FormFields.
 */
function getFormValues(form: Form): FormDto {
    const ret = {} as FormDto;
    for (const prop in form) {
        // since we also want getter-functions, we have to avoid .hasOwnProperty
        // https://masteringjs.io/tutorials/fundamentals/hasownproperty
        const formProp = form[prop as keyof Form] as unknown;
        if (isFormDtoAttribute(prop)) {
            // get decorated attributes
            ret[prop] = form[prop as keyof Form];
        } else if (formProp instanceof FormStep) {
            // get all form steps
            ret[prop] = getFormGroupAndElementValues(
                formProp,
                formProp.disabled,
            );
        }
    }
    return _omitBy(ret, (value) => {
        if (_isNil(value)) {
            return true;
        }
        if (_isArray(value) || _isObject(value) || _isString(value)) {
            return _isEmpty(value);
        }
        return false;
    });
}

/**
 * Main method for converting formConfig (on root or form level) to dto.
 * @param config See config.ts
 */
export function convertFormConfigToFormDto(config: Forms | Form): FormDto {
    if (config instanceof Forms) {
        return {
            ...mapValuesAndClean(config, getFormValues),
        };
    } else if (config instanceof Form) {
        return getFormValues(config);
    }
    return {};
}
