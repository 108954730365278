import { staticImplements } from '@/decorators/staticImplements';
import { httpClient } from '../httpClient/httpClient';
import type { AddressRepositoryInterface } from '@/api/interfaces/address/addressRepository';
import { AddressSuggestions } from '@/api/interfaces/address/addressSuggestions';

@staticImplements<AddressRepositoryInterface>()
export class AddressRepository {
    static async fetchSuggestions(
        order: string[],
        zip: string | null,
        city: string | null,
        street: string | null,
        district: string | null,
    ): Promise<AddressSuggestions> {
        return httpClient.get<AddressSuggestions>(
            `/api/dea/v1/address/autocomplete?${order
                .map((o) => `order=${o}`)
                .join('&')}&zip=${zip ?? ''}&city=${city ?? ''}&district=${
                district ?? ''
            }&street=${street ?? ''}`,
        );
    }
}
