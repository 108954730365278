import { httpClient } from '../httpClient/httpClient';
import type { ContentRepositoryInterface } from '@/api/interfaces/content/contentRepositoryInterface';
import { staticImplements } from '@/decorators/staticImplements';
import { AppContent } from '@/api/interfaces/content/app/appContent';
import { FormContent } from '@/api/interfaces/content/form/formContent';

@staticImplements<ContentRepositoryInterface>()
export class ContentRepository {
    static fetchAppContent(): Promise<AppContent> {
        return httpClient.get<AppContent>('/api/dea/v1/formcontent/app');
    }
    static fetchFormContent(): Promise<FormContent> {
        return httpClient.get<FormContent>('/api/dea/v1/formcontent/form');
    }
}
