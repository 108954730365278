import { MockService } from '../mockService';
import applicationData from './data/applicationData.json';
import applications from './data/applications.json';
import { Application } from '@/api/interfaces/application/application';
import type { ApplicationRepositoryInterface } from '@/api/interfaces/application/applicationRepository';
import { staticImplements } from '@/decorators/staticImplements';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { FormDto } from '@/services/form/dto';
import { ServiceSuccess } from '@/api/interfaces/serviceSuccess';

@staticImplements<ApplicationRepositoryInterface>()
export class ApplicationRepository {
    static fetchApplications(): Promise<Application[]> {
        const mockService = new MockService<Application[]>();
        return mockService.runServiceWithSuccess(applications, 5);
    }

    static getApplicationData(applicationId: string): Promise<FormDto> {
        const mockService = new MockService<FormDto>();

        // fail in 25% of the cases to showcase failures
        if (
            applicationId.endsWith('4') ||
            applicationId.endsWith('5') ||
            applicationId.endsWith('6') ||
            applicationId.endsWith('7')
        ) {
            return mockService.runServiceWithFailure(5);
        }

        return mockService.runServiceWithSuccess(applicationData, 5);
    }

    static copyApplication(applicationId: string): Promise<string> {
        const mockService = new MockService<string>();

        // fail in 25% of the cases to showcase failures
        if (
            applicationId.endsWith('4') ||
            applicationId.endsWith('5') ||
            applicationId.endsWith('6') ||
            applicationId.endsWith('7')
        ) {
            return mockService.runServiceWithFailure(5);
        }

        return mockService.runServiceWithSuccess(
            applicationData.applicationId,
            5,
        );
    }

    static deleteApplication(applicationId: string): Promise<ServiceSuccess> {
        const mockService = new MockService<ServiceSuccess>();

        // fail in 25% of the cases to showcase failures
        if (
            applicationId.endsWith('4') ||
            applicationId.endsWith('5') ||
            applicationId.endsWith('6') ||
            applicationId.endsWith('7')
        ) {
            return mockService.runServiceWithFailure(5);
        }

        return mockService.runServiceWithSuccess({ message: 'OK' }, 5);
    }

    static saveApplication(model: ApplicationForm): Promise<ServiceSuccess> {
        const mockService = new MockService<ServiceSuccess>();

        if (model.location.streetAndHousenumber.housenumber.value === '666') {
            return mockService.runServiceWithFailure(5);
        }

        if (model.location.streetAndHousenumber.housenumber.value === '777') {
            return mockService.runServiceWithSuccess({ message: 'OK' }, 5);
        }

        return mockService.runServiceWithSuccess({ message: 'OK' }, 5);
    }

    static sendApplication(): Promise<ServiceSuccess> {
        const mockService = new MockService<ServiceSuccess>();

        return mockService.runServiceWithSuccess({ message: 'OK' }, 5);
    }
}
