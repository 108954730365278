import { PlantBuilderFormStepContent } from '@/api/interfaces/content/form/steps/plantBuilderFormStepContent';

export const plantBuilderContent: PlantBuilderFormStepContent = {
    stepHeading: 'Anlagenerrichter',
    buttonNext: {
        text: 'Weiter',
    },
    companyName: {
        label: 'Firmenname',
        placeholder: 'Firmenname',
    },
    builderName: {
        label: 'Vor- und Nachname des Ansprechpartners',
        firstName: {
            placeholder: 'Vorname des Ansprechpartners',
        },
        lastName: {
            placeholder: 'Nachname des Ansprechpartners',
        },
    },
    country: {
        label: 'Land',
        summaryLabel: 'Land angegeben als',
        options: {
            deu: {
                label: 'Deutschland',
            },
            aut: {
                label: 'Österreich',
            },
            dnk: {
                label: 'Dänemark',
            },
            fra: {
                label: 'Frankreich',
            },
            nld: {
                label: 'Niederlande',
            },
            pol: {
                label: 'Polen',
            },
            che: {
                label: 'Schweiz',
            },
        },
    },
    zipAndCity: {
        label: 'PLZ und Ort',
        zip: {
            placeholder: 'PLZ',
        },
        city: {
            placeholder: 'Ort',
        },
    },
    streetAndHousenumber: {
        label: 'Straße und Hausnummer',
        street: {
            placeholder: 'Straße',
        },
        housenumber: {
            placeholder: 'Nr.',
        },
    },
    email: {
        label: 'E-Mail',
        placeholder: 'E-Mail',
    },
    phoneNumber1: {
        label: 'Telefon- oder Mobilfunknummer I',
        placeholder: 'Telefon- oder Mobilfunknummer I',
    },
    phoneNumber2: {
        label: 'Telefon- oder Mobilfunknummer II',
        placeholder: 'Telefon- oder Mobilfunknummer II',
    },
};
