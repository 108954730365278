<template>
    <router-link v-if="$scopedSlots.default !== null" class="link" :to="path">
        <slot></slot>
    </router-link>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'AppRouterLink',
    props: {
        path: {
            type: String,
            default: null,
        },
    },
});
</script>

<style lang="postcss" scoped>
.link {
    @apply text-primary no-underline;
}
</style>
