import { staticImplements } from '@/decorators/staticImplements';
import { FileRepositoryInterface } from '@/api/interfaces/file/fileRepositoryInterface';
import { UploadedFileInfo } from '@/api/interfaces/file/uploadedFileInfo';
import { FileUploadResponse } from '@/api/interfaces/file/fileUploadResponse';
import { FileDownloadResponse } from '@/api/interfaces/file/fileDownloadResponse';
import { MockService } from '@/api/mock/mockService';
import { httpClient } from '../httpClient/httpClient';
import { FileRepository as DummyFileRepository } from '../../mock/file/fileRepository';
import { ServiceSuccess } from '@/api/interfaces/serviceSuccess';

@staticImplements<FileRepositoryInterface>()
export class FileRepository {
    static uploadFile(
        file: File,
        scId: string,
        fileName: string,
        applicationId: string,
    ): Promise<FileUploadResponse> {
        if (applicationId === 'kitchenSink') {
            // allow usage of kitchen sink, without real application
            return DummyFileRepository.uploadFile(
                file,
                scId,
                fileName,
                applicationId,
            );
        }

        const formData = new FormData();
        formData.append('scId', scId);
        formData.append('file', file);
        formData.append('fileName', fileName);

        return httpClient.post<FileUploadResponse>(
            `/api/dea/v1/application/${applicationId}/file/upload`,
            {
                data: formData,
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
    }

    static async downloadFile(
        fileId: string,
        applicationId: string,
    ): Promise<FileDownloadResponse> {
        if (applicationId === 'kitchenSink') {
            // allow usage of kitchen sink, without real application
            return DummyFileRepository.downloadFile(fileId, applicationId);
        }

        return httpClient.get<FileDownloadResponse>(
            `/api/dea/v1/application/${applicationId}/file/${fileId}`,
        );
    }

    static deleteFile(fileInfo: UploadedFileInfo): Promise<ServiceSuccess> {
        const mockService = new MockService<ServiceSuccess>();

        if (fileInfo.name === 'failDeleteWithException.pdf') {
            return mockService.runServiceWithFailure(1000);
        }

        return mockService.runServiceWithSuccess({ message: 'OK' }, 1000);
    }
}
