import { template as _template } from 'lodash';

export type InterpolationPlaceholders = { [placeholderName: string]: unknown };

/*
 * Takes the given string and interpolates the provided values to the
 * appropriate placeholders using the template function of lodash.
 * https://lodash.com/docs/4.17.15#template
 *
 * Placeholders are defined using mustache template delimiters:
 * Hello {{ user }}!
 */
export function interpolate(
    string: string,
    placeholders: InterpolationPlaceholders = {},
): string {
    const compiled = _template(string, {
        interpolate: /{{([\s\S]+?)}}/g,
    });
    try {
        return compiled(placeholders);
    } catch {
        return string;
    }
}
