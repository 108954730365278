




























import Vue, { PropType } from 'vue';
import AppLogo from '@/components/molecules/appLogo/AppLogo.vue';
import MetaNavigation from '@/components/molecules/metaNavigation/MetaNavigation.vue';
import MainNavigation from '@/components/molecules/mainNavigation/MainNavigation.vue';
import type { HeaderContent } from '@/api/interfaces/content/app/headerContent';

export default Vue.extend({
    name: 'AppHeader',
    components: {
        AppLogo,
        MetaNavigation,
        MainNavigation,
    },
    props: {
        headerContent: {
            type: Object as PropType<HeaderContent>,
            default: {},
        },
        hamburgerMenuOpen: {
            type: Boolean,
            default: false,
        },
    },
});
