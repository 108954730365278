import { PlantDataFormStepContent } from '@/api/interfaces/content/form/steps/plantDataFormStepContent';
import { infeedManagementContent } from './plantData/infeedManagement';

export const plantDataContent: PlantDataFormStepContent = {
    stepHeading: 'Anlagendaten',
    buttonNext: {
        text: 'Weiter',
    },
    storageConnection: {
        text: '<strong>Anschluss des Speichersystems</strong>',
    },
    newOrExistingApplication: {
        label:
            'Handelt es sich um einen Neuantrag oder die &Auml;nderung eines Altantrages?',
        summaryLabel: 'Antragsart',
        options: {
            change: {
                label: 'Antragsänderung',
            },
            new: {
                label: 'Neuantrag',
            },
        },
    },
    storageBalancingEnergyMarketParticipationType: {
        label: 'Regelenergiemarkt',
        options: {
            positiveAndNegative: {
                label: 'positive und negative Regelenergie',
            },
            onlyNegative: {
                label: 'nur negative Regelenergie (Verbrauch von Strom)',
            },
            onlyPositive: {
                label: 'nur positive Regelenergie (Lieferung von Strom)',
            },
        },
    },
    applicationNumber: {
        label: 'Antragsnummer',
        placeholder: 'Antragsnummer',
        info:
            'Die Antragsnummer finden Sie in Ihrer Eingangsbest&auml;tigung oder Einspeisezusage.',
    },
    storageCharge: {
        label: 'Beladung',
        placeholder: 'Bitte wählen',
        options: {
            onlyFromEeg: {
                label: 'nur aus der EEG - Anlage',
            },
            onlyFromKwkg: {
                label: 'nur aus der KWKG - Anlage',
            },
            fromEegAndKwkg: {
                label: 'aus der EEG - und KWKG - Anlage',
            },
            onlyFromPublicGrid: {
                label: 'nur aus dem öffentlichen Netz',
            },
            fromPublicGridAndEeg: {
                label: 'aus dem öffentlichen Netz und EEG - Anlage',
            },
            fromPublicGridAndKwkg: {
                label: 'aus dem öffentlichen Netz und KWKG - Anlage',
            },
            fromPublicGridAndEegAndKwkg: {
                label: 'aus dem öffentlichen Netz, KWKG und EEG - Anlagen',
            },
        },
    },
    storageDischarge: {
        label: 'Entladung',
        placeholder: 'Bitte wählen',
        options: {
            onlyIntoCustomersGridSelfSupply: {
                label: 'nur in das kundeneigene Netz - Eigenversorgung',
            },
            onlyIntoPublicGridGridFeedIn: {
                label: 'nur in das öffentliche Netz - Netzeinspeisung',
            },
            intoCustomersGridAndPublicGridBivalent: {
                label: 'in das kundeneigene und öffentliche Netz (Bivalent)',
            },
        },
    },
    storagePublicDischargeWarning: {
        text:
            'Bei einer Entladung in das öffentliche Netz wird der Eigenverbrauch nicht optimiert. Dadurch können Ihnen im Vergleich zum optimierten Eigenverbrauch <strong>wirtschaftliche Nachteile</strong> entstehen. Bitte prüfen Sie, ob Sie tatsächlich in das kundeneigene und öffentliche Netz (Bivalent) entladen möchten. Bei einer falschen Angabe muss der Speicher neu angemeldet werden',
    },
    tenantElectricitySurcharge: {
        label:
            'Der Anlagenbetreiber m&ouml;chte den Mieterstromzuschlag gem&auml;&szlig; &sect;&nbsp;21&nbsp;EEG beanspruchen',
    },
    requirementTenantElectricitySurchargeHint: {
        text:
            '<strong>Anforderungen f&uuml;r den Anspruch auf Mieterstromzuschlag gem&auml;&szlig; &sect;&nbsp;21&nbsp;EEG</strong>',
    },
    minimumRequirementLivingSpace: {
        label:
            'Mindestens 40% Prozent der Fl&auml;che des Geb&auml;udes dienen dem Wohnen.',
    },
    electricityConsumptionWithinThisBuilding: {
        label:
            'Der an Letztverbraucher gelieferte Strom aus der Solaranlage wird innerhalb dieses Geb&auml;ude oder in Wohngeb&auml;uden oder Nebenanlagen in demselben Quartier, in dem auch dieses Geb&auml;ude liegt, verbraucht.',
    },
    plantBuildingType: {
        label: 'Um was f&uuml;r eine Art Geb&auml;ude handelt es sich?',
        options: {
            noResidentialBuilding: {
                label: 'kein Wohngebäude',
            },
            residentialBuilding: {
                label: 'Wohngebäude',
            },
        },
        info:
            'Definition Wohngebäude nach $3 Abs. 50: "Wohngebäude jedes Gebäude, das nach seiner Zweckbestimmung überwiegend dem Wohnen dient, einschließlich Wohn-, Alten- und Pflegeheimen sowie ähnlichen Einrichtungen."',
    },
    plantPosition: {
        label: 'Wo befindet sich die Anlage?',
        options: {
            indoor: {
                label: 'im Innenbereich nach § 34 des Baugesetzbuches',
            },
            outdoor: {
                label: 'im Außenbereich nach § 35 des Baugesetzbuches',
            },
        },
    },
    noAdditionalNetworkForEndConsumer: {
        label:
            'Der an Letztverbraucher gelieferte Strom wird nicht durch ein Netz durchgeleitet.',
    },
    federalNetworkAgencyHint: {
        text:
            '<p><strong>Erf&uuml;llung der gesetzlichen Meldepflichten bei der Bundesnetzagentur</strong></p><p>Folgende Nachweise werden sp&auml;testens ein Monat nach Inbetriebnahme beim Netzbetreiber eingereicht:</p>',
    },
    proofOfRegistrationPhotovoltaic: {
        label: 'Nachweis &uuml;ber die Registrierung der Solaranlage.',
    },
    proofOfAllocationOfTenantElectricitySurcharge: {
        label:
            'Nachweis &uuml;ber die Zuordnung der Ver&auml;u&szlig;erungsform &ldquo;Mieterstromzuschlag&ldquo;.',
    },
    declarationEnwgHint: {
        text: '<strong>Erkl&auml;rung zum EnWG</strong>',
    },
    plantOperatorKnowsEnwgSpecifications: {
        label:
            'Dem Anlagenbetreiber als Mieterstromlieferant sind die Vorgaben aus dem Energiewirtschaftsgesetz (EnWG) zum Mieterstrom bzw. zu den Mieterstromvertr&auml;gen bekannt. Die Einhaltung der &sect;&nbsp;42 und &sect;&nbsp;42a&nbsp;EnWG wird best&auml;tigt.',
    },
    newOrExistingPlant: {
        label: 'Handelt es sich bei Ihrer geplanten Erzeugungsanlage um',
        summaryLabel: 'Neu- oder Bestandsanlage',
        options: {
            additional: {
                label: 'eine weitere Erzeugungsanlage',
            },
            first: {
                label: 'die erste Erzeugungsanlage',
            },
            performanceIncrease: {
                label:
                    'eine Erweiterung einer Bestandsanlage (Leistungserhöhung) auf diesem Grundstück, Gebäude, Betriebsgelände, oder sonst in räumlicher Nähe',
            },
        },
    },
    newOrExistingPlantStorage: {
        label: 'Handelt es sich bei Ihrer geplanten Stromspeicheranlage um',
        summaryLabel: 'Neu- oder Bestandsanlage',
        options: {
            additional: {
                label: 'eine weitere Stromspeicheranlage',
            },
            first: {
                label: 'die erste Stromspeicheranlage',
            },
            capacityIncrease: {
                label:
                    'eine Änderung der Speicherkapazität (ohne Änderung der Nennentladeleistung)',
            },
        },
    },
    powerHeatCouplingPlantType: {
        label: 'Anlagentyp',
        options: {
            highEfficiencyChpPlant: {
                label: 'Hocheffiziente KWK-Anlage',
            },
            nonHighEfficiencyChpPlant: {
                label:
                    'Nicht hocheffiziente KWK-Anlage oder konventionelle Erzeugungsanlage',
            },
        },
    },
    powerHeatCouplingPaymentsBasedOnLawHint: {
        text:
            '<p>Dem Anlagenbetreiber ist bekannt, dass EWE NETZ Zahlungen allein auf nach dem Gesetz bestehende Anspr&uuml;che leistet.</p>\n<p>Solche Anspr&uuml;che k&ouml;nnen Anspr&uuml;che auf die Zahlung von Zuschl&auml;gen nach den &sect;&sect; 6 - 13 KWKG, Anspr&uuml;che auf Zahlung des &uuml;blichen Preises nach &sect; 4 Abs. 3 und auch Anspr&uuml;che auf Zahlung sog. vermiedenes Netznutzungsentgelte nach &sect; 18 StromNEV sein. Diese Anspr&uuml;che sind in der Anlage &bdquo;Hinweisblatt f&uuml;r Stromeinspeisung&ldquo; dargestellt.</p>\n<p>Der Anlagenbetreiber weist das Vorliegen der gesetzlichen Voraussetzungen des jeweiligen Anspruchs EWE NETZ nach:</p>',
    },
    bafaApprovalWillBeSubmittedLater: {
        label:
            'BAFA-Zulassungsbescheid bzw. die Bescheinigung &uuml;ber die elektronische Anzeige f&uuml;r KWK-Anlagen bis 50 kWel wird nachgereicht',
    },
    pvInstallationType: {
        label: 'Die Solaranlage ist wie folgt installiert:',
        options: {
            residentialOrSimilarBuilding: {
                label:
                    'Die Solaranlage ist ausschließlich in, an oder auf<br>a) einem <strong>Wohngebäude</strong> oder<br>b) einem anderen Gebäude im Innenbereich einer Gemeinde nach § 34 BauGB oder<br>c) einer Lärmschutzwand<br>im Sinne des § 48 Abs. 2 EEG angebracht.',
            },
            outsideNonResidentialBuilding: {
                label:
                    '<p class="headline">Installation in an oder auf einem Gebäude (EEG §48 Abs.3 Nr.1, 2 oder 3)</p>Die Solaranlage ist ausschließlich in, an oder auf einem Gebäude angebracht, das <strong>kein Wohngebäude</strong> ist und im <strong>Außenbereich</strong> nach § 35 des BauGB errichtet wurde. Die Solaranlage erfüllt die Voraussetzungen von § 48 Abs. 3 Nr 1, 2 oder 3 EEG.',
            },
            outsideNonResidentialBuildingEEG48Abs1: {
                label:
                    '<p class="headline"> Installation in an oder auf einem Gebäude (EEG §48 Abs.1 Nr.1)</p>Die Solaranlage ist ausschließlich in, an oder auf einem Gebäude angebracht, das <strong>kein Wohngebäude</strong> ist und im <strong>Außenbereich</strong> nach § 35 des BauGB errichtet wurde. Die Solaranlage erfüllt die Voraussetzungen von § 48 Abs. 3 Nr 1, 2 oder 3 EEG.',
            },
            structuralFacility: {
                label:
                    '<p class="headline">Installation in, an oder auf einer baulichen Anlage</p>\n<p class="description">Die Solaranlage(n) ist/ sind in, an oder auf einer baulichen Anlage im Sinne des &sect; 48 Abs. 1 Nr. 1 EEG angebracht. Diese sind <strong>vorrangig</strong> zu anderen Zwecken als der Erzeugung von Strom aus solarer Strahlungsenergie errichtet worden.</p>',
            },
            nextToResidentialBuilding: {
                label:
                    '<p class="headline">Installation neben einem Wohngeb&auml;ude (Sonderregelung Reetdach, Denkmal etc.)</p>\n<p class="description">Die Solaranlage ist im Sinne des &sect; 48 Abs. 1 Nummer 1a auf einem Grundst&uuml;ck innerhalb eines im Zusammenhang bebauten Ortsteils im Sinn des &sect; 34 des Baugesetzbuchs errichtet worden. Auf diesem Grundst&uuml;ck besteht zum Zeitpunkt der Inbetriebnahme der Anlage ein Wohngeb&auml;ude, das nach Ma&szlig;gabe der Verordnung nach &sect; 95 Nummer 3 nicht dazu geeignet ist, dass auf, an oder in ihm eine Solaranlage errichtet werden kann. Die Grundfl&auml;che der Anlage darf die Grundfl&auml;che dieses Wohngeb&auml;udes nicht &uuml;berschreiten und die Anlage eine installierte Leistung von nicht mehr als 20 Kilowatt haben.</p>',
            },
            publicWasteDisposalFacilities: {
                label:
                    '<p class="headline">Installation in Zusammenhang mit einem Planfeststellungsverfahren f&uuml;r die Errichtung und den Betrieb &ouml;ffentlich zug&auml;nglicher Abfallbeseitigungsanlagen</p>\n<p class="description">Die Solaranlage(n) ist/ sind im Sinne des § 48 Abs. 1 Nummer 2 EEG auf einer Fläche errichtet worden ist, für die ein Planfeststellungsverfahren, ein sonstiges Verfahren mit den Rechtswirkungen der Planfeststellung für Vorhaben von überörtlicher Bedeutung oder ein Verfahren auf Grund des Bundes-Immissionsschutzgesetzes für die Errichtung und den Betrieb öffentlich zugänglicher  Abfallbeseitigungsanlagen durchgeführt worden ist und die Gemeinde wurde beteiligt und die Fläche ist kein entwässerter landwirtschaftlich genutzter Moorboden.</p>',
            },
            approvedDevelopmentPlan: {
                label:
                    '<p class="headline">Installation im Bereich eines beschlossenen Bebauungsplans</p>\n<p class="description">Die Solaranlage(n) ist/ sind im Bereich eines beschlossenen Bebauungsplans im Sinne des § 30 BauGB errichtet worden. Die Fläche ist kein entwässerter landwirtschaftlich genutzter Moorboden und die Voraussetzungen von § 48 Abs. 1 Nr. 3 Buchstabe a), b) oder c) liegen vor. </p>',
            },
            artificialWaterBody: {
                label:
                    '<p class="headline">Besondere Solaranlage auf landwirtschaftlichen Fl&auml;chen oder Parkplatzfl&auml;chen</p>\n<p class="description">Die Solaranlage(n) ist/ sind auf einer Fläche errichtet worden, die ein künstliches Gewässer im Sinn des § 3 Nummer 4 des Wasserhaushaltsgesetzes oder ein erheblich verändertes Gewässer im Sinn des § 3 Nummer 5 des Wasserhaushaltsgesetzes ist (§ 48 Abs. 1 Nummer 4 EEG).</p>',
            },
            agriculturalLandOrParkingLots: {
                label:
                    '<p class="headline">Besondere Solaranlage auf landwirtschaftlichen Flächen oder Parkplatzflächen</p>\n<p class="description">Die Solaranlage(n) ist/ sind eine besondere Solaranlage, die den Anforderungen entspricht, die in einer Festlegung der Bundesnetzagentur nach &sect; 85c EEG an sie gestellt werden.</p>',
            },
        },
    },
    pvInstallationTypeForAgriculturalLandOrParkingLots: {
        label: 'Die Solaranlage ist errichtet worden',
        options: {
            agriculturalLand: {
                label:
                    'auf Ackerfl&auml;chen, die kein Moorboden sind und nicht rechtsverbindlich als Naturschutzgebiet im Sinn des &sect; 23 des Bundesnaturschutzgesetzes oder als Nationalpark im Sinn des &sect; 24 des Bundesnaturschutzgesetzes festgesetzt worden sind, mit gleichzeitigem Nutzpflanzenanbau auf derselben Fl&auml;che',
            },
            permanentCrops: {
                label:
                    'auf Fl&auml;chen, die kein Moorboden sind und nicht rechtsverbindlich als Naturschutzgebiet im Sinn des &sect; 23 des Bundesnaturschutzgesetzes oder als Nationalpark im Sinn des &sect; 24 des Bundesnaturschutzgesetzes festgesetzt worden sind, mit gleichzeitiger landwirtschaftlicher Nutzung in Form eines Anbaus von Dauerkulturen oder mehrj&auml;hrigen Kulturen auf derselben Fl&auml;che',
            },
            grassLand: {
                label:
                    'auf Gr&uuml;nland bei gleichzeitiger landwirtschaftlicher Nutzung als Dauergr&uuml;nland, wenn die Fl&auml;che kein Moorboden ist, nicht rechtsverbindlich als Naturschutzgebiet im Sinn des &sect; 23 des Bundesnaturschutzgesetzes oder als Nationalpark im Sinn des &sect; 24 des Bundesnaturschutzgesetzes festgesetzt worden ist, nicht in einem Natura 2000-Gebiet im Sinn des &sect; 7 Absatz 1 Nummer des Bundesnaturschutzgesetzes liegt und kein Lebensraumtyp ist, der in Anhang I der Richtlinie 92/43/EWG aufgef&uuml;hrt ist',
            },
            parkingLots: {
                label: 'auf Parkplatzfl&auml;chen',
            },
            peatSoils: {
                label:
                    'auf Moorb&ouml;den, die entw&auml;ssert und landwirtschaftlich genutzt worden sind, wenn die Fl&auml;chen mit der Errichtung der Solaranlage dauerhaft wiedervern&auml;sst werden',
            },
        },
    },
    storageCoupling: {
        label: 'Kopplung',
        info:
            '<p>AC-gekoppelt: Anschluss im Verbraucherpfad. Be- und Entladung &uuml;ber seperaten WR.</p>\n<p>DC-gekoppelt: Anschluss im Erzeugungspfad. Beladung DC-seitig. Entladung &uuml;ber gemeinsamen WR.</p>',
        options: {
            acCoupled: {
                label: 'AC-gekoppelt',
            },
            dcCoupled: {
                label: 'DC-gekoppelt',
            },
        },
    },
    storageConnectionConcept: {
        label: 'Anschlusskonzept',
        options: {
            powerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant: {
                label:
                    'Stromerzeugungsanlage mit Speicher im Erzeugungspfad und Verbrauchsanlage',
            },
            powerGenerationPlantWithStorageInTheConsumerPathAndConsumptionPlant: {
                label:
                    'Stromerzeugungsanlage mit Speicher im Verbraucherpfad und Verbrauchsanlage',
            },
            storageFacilityWithPowerGenerationPlantAndWithoutConsumptionPlant: {
                label:
                    'Speicher mit Stromerzeugungsanlage und ohne Verbrauchsanlage',
            },
            storageWithoutPowerGenerationPlantAndWithoutConsumptionPlant: {
                label:
                    'Speicher ohne Stromerzeugungsanlage und ohne Verbrauchsanlage',
            },
        },
    },
    initialCommissioningDate: {
        label: 'Bitte tragen Sie hier den Termin der Erstinbetriebnahme ein.',
        info:
            'Das Erstinbetriebnahmedatum finden Sie auf dem Inbetriebsetzungsprotokoll der Anlage. Bitte geben Sie das Datum im Format TT.MM.JJJJ ein.',
        placeholder: 'Termin der Erstinbetriebnahme',
    },
    electricityTaxExemptionHint: {
        text:
            'Sollte der Anlagenbetreiber f&uuml;r den in der/den DEA erzeugten Strom Stromsteuerbefreiung in Anspruch nehmen, informiert er EWE NETZ hier&uuml;ber unverz&uuml;glich.',
        info:
            'Soweit Stromsteuerbefreiung in Anspruch genommen wird, entf&auml;llt der Anspruch auf Zahlung der EEG-Verg&uuml;tung.',
    },
    plantWasAlreadyCommissioned: {
        label:
            'Die geplante Erzeugungsanlage wurde schon einmal in Betrieb gesetzt',
    },
    storageBalancingEnergyMarketParticipation: {
        label:
            'Teilnahme am Regelenergiemarkt (nur in Verbindung mit präqualifiziertem Anbieter möglich)',
    },
    ...infeedManagementContent,
};
