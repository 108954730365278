import { MockService } from '../mockService';
import { staticImplements } from '@/decorators/staticImplements';
import { MkfRepositoryInterface } from '@/api/interfaces/mkf/mkfRepositoryInterface';
import mkf from './data/mkf.json';
import { Mkf } from '@/api/interfaces/mkf/mkf';

@staticImplements<MkfRepositoryInterface>()
export class MkfRepository {
    static async getMkfData(): Promise<Mkf> {
        const mockService = new MockService<Mkf>();
        return mockService.runServiceWithSuccess(mkf, 5);
    }
}
